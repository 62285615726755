<script setup>
import { ModelSelect } from "vue-search-select"
import { formatWeight } from "@/lib/util/formatter.js"

import ParkingSpaceAPI from '@/lib/api/parkingspace.js';
import GuestAPI from '@/lib/api/guest.js';
import ProduceAPI from '@/lib/api/produce.js';
import ShiftbookAPI from '@/lib/api/shiftbook.js';

import { ref, watch, computed, onMounted } from 'vue';

const selected = ref({
    parkingspace: null,
    material: null,
    type: null,
    weight: 0,
    balls: 0,
    haType: 1,
});

/**
 * Schritt 1: alle verfügbaren Parkingspaces, von denen man aufgeben darf,
 * listen
 */
const availableParkingSpaces = ref(null);
onMounted(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    selected.value.weight = urlParams.get('WT') * 1;
    if (urlParams.get('hatype') == 'after')
        selected.value.haType = 0;
    else if (urlParams.get('hatype') == 'before')
        selected.value.haType = 2;

    (async () => {
        availableParkingSpaces.value = null;
        var s = (await GuestAPI.GetParkingspaces({ offset: 0, count: 9999, sortby: 'sort', sortdir: 'asc' })).data;
        if (s.error)
            throw s.message;
        var ret = [];
        for (var i = 0; i < s.length; i++) {
            if (s[i].enabled == false) continue;
            if (s[i].usage.place == false) continue;
            ret.push({
                'value': s[i].id,
                'text': s[i].name
            });
        }
        availableParkingSpaces.value = ret;
    })();
});

const wipeParkingSpace = () => {
    selected.value.parkingspace = null;
    selected.value.material = null;
    selected.value.type = null;
    selected.value.balls = 0;
    currentMaterialForParkingspace.value = null;
};

/**
 * Schritt 2: alle Materialien auf diesem parkingspace listen
 */
const currentMaterialOnSelectedParkingspace = ref(null);
watch(selected, () => {
    (async () => {
        if (selected.value.parkingspace === null)
            return;
        var needToFetch = false;

        if (currentMaterialOnSelectedParkingspace.value === null)
            needToFetch = true;
        else if (selected.value.parkingspace !== currentMaterialOnSelectedParkingspace.value.id)
            needToFetch = true;
        if (!needToFetch) return;
        const data = (await GuestAPI.ParkingspaceByID(selected.value.parkingspace)).data;
        // Auswahl mittlerweile geändert?
        if (data.id !== selected.value.parkingspace)
            return;
        data.utilization = data.utilization.filter((s) => s.still_there);
        currentMaterialOnSelectedParkingspace.value = data;
    })();
}, { deep: true });
const currentMaterialOnSelectedParkingspaceSelect = computed(() => {
    if (currentMaterialOnSelectedParkingspace.value == null)
        return;
    var ret = [];
    for (var i = 0; i < currentMaterialOnSelectedParkingspace.value.utilization.length; i++) {
        ret.push({
            value: currentMaterialOnSelectedParkingspace.value.utilization[i].material.id,
            text: currentMaterialOnSelectedParkingspace.value.utilization[i].material.name,
        });
    }
    return ret;
});
const selectedMaterial = computed(() => {
    if (currentMaterialOnSelectedParkingspace.value == null)
        return null;
    return currentMaterialOnSelectedParkingspace.value.utilization.find((s) => s.material.id == selected.value.material);
});
const wipeMaterial = () => {
    selected.value.material = null;
    selected.value.type = null;
    selected.value.balls = 0;
};
const wipeType = () => {
    selected.value.type = null;
    selected.value.balls = 0;
};

const canUserSubmit = computed(() => {
    if (selected.value.parkingspace == null)
        return false;
    if (selected.value.material == null)
        return false;
    if (selected.value.type == null)
        return false;
    if (selected.value.weight == 0)
        return false;
    if (selectedMaterial.value == null)
        return false;
    if (selected.value.type == "ball") {
        if (selected.value.balls == 0)
            return false;
        if (selected.value.balls > selectedMaterial.value.ball_pcs.sum)
            return false;
    }
    if (selected.value.type == "bigbag") {
        if (selected.value.balls == 0)
            return false;
        if (selected.value.balls > selectedMaterial.value.bigbag_pcs.sum)
            return false;
    }
    return true;
});

const emit = defineEmits(['submit']);
const submit = async () => {
    if (!canUserSubmit.value)
        return;

    try {
        // Die GuestAPI hat selber nen Toast raus, wenn ein Fehler auftritt
        await GuestAPI.placeMaterialToProduction(selected.value);
        console.log(`emit submit`)
        emit('submit');
    } catch (_e) { }
};
</script>
<template>
    <div class="card">
        <div class="card-body">
            <strong>Neue Handaufgabe</strong>
            <hr />

            <!-- 
                HA Typ
            -->
            <label class="d-block mt-2">Aufgabentyp</label>
            <select class="form-control" v-model="selected.haType">
                <option value="1">Keine Handaufgabe</option>
                <option value="2">HA vor Wäsche</option>
                <option value="0">HA nach Wäsche</option>
            </select>

            <!--
                Gewicht (unveränderlich)
            -->
            <label class="d-block mt-2">Gewicht</label>
            <div class="input-group">
                <input class="form-control" disabled :value="formatWeight(selected.weight)" />
            </div>

            <!--
                Plätze
            -->
            <template v-if="availableParkingSpaces == null">
                Lade Plätze...
            </template>
            <template v-else-if="availableParkingSpaces != null && selected.parkingspace == null">
                <label class="d-block mt-2">Quell-Lager</label>
                <ModelSelect :options="availableParkingSpaces" v-model="selected.parkingspace"
                    placeholder="Bitte auswählen" class="input-xl" />
            </template>
            <template v-else>
                <label class="d-block mt-2">Quell-Lager</label>
                <div class="input-group">
                    <input class="form-control" disabled
                        :value="availableParkingSpaces.find((s) => s.value == selected.parkingspace).text" />
                    <span class="input-group-text text-danger" role="button" @click="wipeParkingSpace()">
                        <font-awesome-icon icon="fa-solid fa-trash-can"></font-awesome-icon>
                    </span>
                </div>
            </template>

            <!--
                Material
            -->
            <template v-if="selected.parkingspace !== null && currentMaterialOnSelectedParkingspace == null">
                Lade Material auf Stellplatz...
            </template>
            <template v-else-if="selected.parkingspace !== null && selected.material === null">
                <label class="d-block mt-2">Material</label>
                <ModelSelect :options="currentMaterialOnSelectedParkingspaceSelect" v-model="selected.material"
                    placeholder="Bitte auswählen" class="input-xl" />
            </template>
            <template v-else-if="selected.parkingspace !== null && selected.material !== null">
                <label class="d-block mt-2">Material</label>
                <div class="input-group">
                    <input class="form-control" disabled :value="selectedMaterial.material.name" />
                    <span class="input-group-text text-danger" role="button" @click="wipeMaterial()">
                        <font-awesome-icon icon="fa-solid fa-trash-can"></font-awesome-icon>
                    </span>
                </div>
            </template>

            <!--
                Typ (Ballen/Brikets/Bigbags)
            -->
            <template v-if="selected.material !== null && selected.type == null">
                <label class="d-block mt-2">Typ</label>
                <select class="form-control" v-model="selected.type">
                    <option value="ball" v-if="selectedMaterial.ball_weight.sum >= selected.weight">
                        Ballen ({{ formatWeight(selectedMaterial.ball_weight.sum) }} / {{ selectedMaterial.ball_pcs.sum
                        }} Stk.)
                    </option>
                    <option value="ball" v-else-if="selectedMaterial.ball_weight.sum > 0" disabled>
                        Ballen (nur {{ formatWeight(selectedMaterial.ball_weight.sum) }})
                    </option>
                    <option value="weight" v-if="selectedMaterial.weight.sum >= selected.weight">
                        Lose ({{ formatWeight(selectedMaterial.weight.sum) }})
                    </option>
                    <option value="weight" v-else-if="selectedMaterial.weight.sum > 0" disabled>
                        Lose (nur {{ formatWeight(selectedMaterial.weight.sum) }})
                    </option>
                    <option value="bigbag" v-if="selectedMaterial.bigbag_weight.sum >= selected.weight">
                        Bigbags ({{ formatWeight(selectedMaterial.bigbag_weight.sum) }} / {{
                            selectedMaterial.bigbag_pcs.sum
                        }} Stk.)
                    </option>
                    <option value="bigbag" v-else-if="selectedMaterial.bigbag_weight.sum > 0" disabled>
                        Bigbags (nur {{ formatWeight(selectedMaterial.bigbag_weight.sum) }})
                    </option>
                </select>
            </template>
            <template v-else-if="selected.material !== null && selected.type != null">
                <label class="d-block mt-2">Typ</label>
                <div class="input-group">
                    <input class="form-control" v-if="selected.type == 'ball'" disabled
                        :value="'Ballen (' + formatWeight(selectedMaterial.ball_weight.sum) + ' / ' + selectedMaterial.ball_pcs.sum + ' Stk.)'" />
                    <input class="form-control" v-else-if="selected.type == 'weight'" disabled
                        :value="'Lose (' + formatWeight(selectedMaterial.weight.sum) + ')'" />
                    <input class="form-control" v-else-if="selected.type == 'bigbag'" disabled
                        :value="'Bigbag (' + formatWeight(selectedMaterial.bigbag_weight.sum) + ' / ' + selectedMaterial.bigbag_pcs.sum + ' Stk.)'" />
                    <span class="input-group-text text-danger" role="button" @click="wipeType()">
                        <font-awesome-icon icon="fa-solid fa-trash-can"></font-awesome-icon>
                    </span>
                </div>
            </template>

            <template v-if="selected.material !== null && selected.type == 'ball'">
                <label class="d-block mt-2">Ballen</label>
                <input type="number" class="form-control input-xl" v-model="selected.balls" />
                <span v-if="selected.balls == 0" class="text-danger">Mindestens 1 angeben.</span>
                <span v-else-if="selected.balls > selectedMaterial.ball_pcs.sum" class="text-danger">
                    Es sind nur {{ selectedMaterial.ball_pcs.sum }} Ballen auf diesem Stellplatz
                </span>
            </template>
            <template v-if="selected.material !== null && selected.type == 'bigbag'">
                <label class="d-block mt-2">Bigbags</label>
                <input type="number" class="form-control input-xl" v-model="selected.balls" />
                <span v-if="selected.balls == 0" class="text-danger">Mindestens 1 angeben.</span>
                <span v-else-if="selected.balls > selectedMaterial.bigbag_pcs.sum" class="text-danger">
                    Es sind nur {{ selectedMaterial.bigbag_pcs.sum }} Bigbags auf diesem Stellplatz
                </span>
            </template>

            <div class="row">
                <div class="col-6">
                    &nbsp;
                </div>
                <div class="col-6 mt-3">
                    <button class="btn btn-block btn-sm w-100 btn-outline-primary"
                        :class="{ 'disabled': !canUserSubmit }" @click="submit()">
                        Aufgeben
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.card {
    align-self: center;
}

.card * {
    font-size: 120% !important;
}

.card :deep(.ui .text),
.card :deep(.ui input.search),
.card :deep(.ui .item),
select option,
.input-xl {
    font-size: 120% !important;
}

label {
    font-weight: bold;
}
</style>