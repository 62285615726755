<script setup>
import { computed } from 'vue';
import Header from './Header.vue';

import MenuItem from './Item.vue';
import BottomMenu from './BottomMenu.vue';

import { useLayoutStore } from '@/stores/layout';
const layoutStore = useLayoutStore();

import { useCurrentUserStore } from '@/stores/currentUser';
const currentUser = useCurrentUserStore();

const showFirstDivider = computed(() => {
    return currentUser.hasPermission("material", "read") || currentUser.hasPermission("inbound", "read") || currentUser.hasPermission("parkingspace", "read");
});

const showSecondDivider = computed(() => {
    return currentUser.hasPermission("customers", "read") || currentUser.hasPermission("orders", "read") || currentUser.hasPermission("bigbags", "read") || currentUser.hasPermission("storagelocation", "read");
});

const showThirdDivider = computed(() => {
    return currentUser.hasPermission("components", "read") || currentUser.hasPermission("shiftbook", "read");
});

const showFourthDivider = computed(() => {
    return currentUser.hasPermission("users", "read") || currentUser.hasPermission("maintenance_template", "read");
});

const showFifthDivider = computed(() => {
    return currentUser.hasPermission("reports", "read") || currentUser.hasPermission("reports_presence", "read");
});
</script>
<template>
    <div id="mainSidebar" class="d-flex flex-column flex-shrink-0 p-3 pt-0 text-white bg-dark"
        :class="{ 'active': layoutStore.isMobileSidebarActive }">
        <Header />
        <ul class="nav nav-pills flex-column mb-auto">
            <MenuItem link="/" tag="home">
            <template #icon>
                <font-awesome-icon icon="fa-solid fa-house" />
            </template>
            <template #name>Home</template>
            </MenuItem>

            <!-- Erster Block -->
            <li v-if="showFirstDivider">
                <hr />
            </li>
            <PermissionComponent type="material" min="read">
                <MenuItem link="/material" tag="material">
                <template #icon>
                    <font-awesome-icon icon="fa-solid fa-bottle-water" />
                </template>
                <template #name>Materialien</template>
                </MenuItem>
            </PermissionComponent>
            <PermissionComponent type="inbound" min="read">
                <MenuItem link="/inbound" tag="inbound">
                <template #icon>
                    <font-awesome-icon icon="fa-solid fa-arrow-right-to-bracket" />
                </template>
                <template #name>Materialeingang</template>
                </MenuItem>
            </PermissionComponent>
            <PermissionComponent type="parkingspace" min="read">
                <MenuItem link="/parkingspace" tag="parkingspace">
                <template #icon>
                    <font-awesome-icon icon="fa-solid fa-cube" />
                </template>
                <template #name>Materiallager</template>
                </MenuItem>
            </PermissionComponent>

            <!-- Zweiter Block -->
            <li v-if="showSecondDivider">
                <hr />
            </li>
            <PermissionComponent type="customers" min="read">
                <MenuItem link="/customer" tag="customer">
                <template #icon>
                    <font-awesome-icon icon="fa-solid fa-user" />
                </template>
                <template #name>Kunden</template>
                </MenuItem>
            </PermissionComponent>
            <PermissionComponent type="orders" min="read">
                <MenuItem link="/order" tag="order">
                <template #icon>
                    <font-awesome-icon icon="fa-solid fa-shopping-cart" />
                </template>
                <template #name>Aufträge</template>
                </MenuItem>
            </PermissionComponent>
            <PermissionComponent type="bigbags" min="read">
                <MenuItem link="/bigbag" tag="bigbag">
                <template #icon>
                    <font-awesome-icon icon="fa-solid fa-suitcase" />
                </template>
                <template #name>Big Bags</template>
                </MenuItem>
            </PermissionComponent>
            <PermissionComponent type="storagelocation" min="read">
                <MenuItem link="/storagelocation" tag="storagelocation">
                <template #icon>
                    <font-awesome-icon icon="fa-solid fa-warehouse" />
                </template>
                <template #name>Verkaufslager</template>
                </MenuItem>
            </PermissionComponent>

            <!-- Dritter Block -->
            <li v-if="showThirdDivider">
                <hr />
            </li>
            <PermissionComponent type="components" min="read">
                <MenuItem link="/component" tag="component">
                <template #icon>
                    <font-awesome-icon icon="fa-solid fa-cogs" />
                </template>
                <template #name>Komponenten</template>
                </MenuItem>
            </PermissionComponent>
            <PermissionComponent type="shiftbook" min="read">
                <MenuItem link="/shiftbook" tag="shiftbook">
                <template #icon>
                    <font-awesome-icon icon="fa-book fa-book" />
                </template>
                <template #name>Schichtbuch</template>
                </MenuItem>
            </PermissionComponent>

            <!-- Vierter Block -->
            <li v-if="showFourthDivider">
                <hr />
            </li>
            <PermissionComponent type="users" min="read">
                <MenuItem link="/user" tag="user">
                <template #icon>
                    <font-awesome-icon icon="fa-book fa-user-shield" />
                </template>
                <template #name>Benutzer</template>
                </MenuItem>
            </PermissionComponent>
            <PermissionComponent type="users" min="maintenance_template">
                <MenuItem link="/maintenance/template" tag="maintenance_template">
                <template #icon>
                    <font-awesome-icon icon="fa-book fa-wrench" />
                </template>
                <template #name>Wartungsvorlagen</template>
                </MenuItem>

            </PermissionComponent>

            <!-- Fünfter Block Block -->
            <li v-if="showFifthDivider">
                <hr />
            </li>
            <PermissionComponent type="reports_presence" min="read">
                <MenuItem link="/report/member" tag="report.member">
                <template #icon>
                    <font-awesome-layers>
                        <font-awesome-icon icon="fa-solid fa-chart-simple" transform="grow-6" class="text-light" />
                        <font-awesome-icon icon="fa-solid fa-user" transform="shrink-2 down-4.2 right-11"
                            class="text-info" />
                    </font-awesome-layers>
                </template>
                <template #name>Anwesenheiten</template>
                </MenuItem>
            </PermissionComponent>
            <PermissionComponent type="reports" min="read">
                <MenuItem link="/report/produce" tag="report.produce">
                <template #icon>
                    <font-awesome-layers>
                        <font-awesome-icon icon="fa-solid fa-chart-simple" transform="grow-6" class="text-light" />
                        <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket"
                            transform="shrink-2 down-4.2 right-11" class="text-danger" />
                    </font-awesome-layers>
                </template>
                <template #name>Input-Mat.</template>
                </MenuItem>
            </PermissionComponent>
            <PermissionComponent type="reports" min="read">
                <MenuItem link="/report/annualoverview" tag="report.annualoverview">
                <template #icon>
                    <font-awesome-layers>
                        <font-awesome-icon icon="fa-solid fa-chart-simple" transform="grow-6" class="text-light" />
                        <font-awesome-icon icon="fa-solid fa-chart-simple" transform="shrink-2 down-4.2 right-11"
                            class="text-warning" />
                    </font-awesome-layers>
                </template>
                <template #name>Jahresüberblick</template>
                </MenuItem>
            </PermissionComponent>
            <PermissionComponent type="reports" min="read">
                <MenuItem link="/report/inventory" tag="report.inventory">
                <template #icon>
                    <font-awesome-layers>
                        <font-awesome-icon icon="fa-solid fa-chart-simple" transform="grow-6" class="text-light" />
                        <font-awesome-icon icon="fa-solid fa-code-compare" transform="shrink-2 down-4.2 right-11"
                            class="text-danger" />
                    </font-awesome-layers>
                </template>
                <template #name>Inventur</template>
                </MenuItem>
            </PermissionComponent>
            <PermissionComponent type="reports" min="read">
                <MenuItem link="/report/bigbag" tag="report.bigbag">
                <template #icon>
                    <font-awesome-layers>
                        <font-awesome-icon icon="fa-solid fa-chart-simple" transform="grow-6" class="text-light" />
                        <font-awesome-icon icon="fa-solid fa-suitcase" transform="shrink-2 down-4.2 right-11"
                            class="text-warning" />
                    </font-awesome-layers>
                </template>
                <template #name>Output-Mat.</template>
                </MenuItem>
            </PermissionComponent>
            <PermissionComponent type="reports" min="read">
                <MenuItem link="/report/bigbag_v2" tag="report.bigbag.2">
                <template #icon>
                    <font-awesome-layers>
                        <font-awesome-icon icon="fa-solid fa-chart-simple" transform="grow-6" class="text-light" />
                        <font-awesome-icon icon="fa-solid fa-suitcase" transform="shrink-2 down-4.2 right-11"
                            class="text-warning" />
                    </font-awesome-layers>
                </template>
                <template #name>Output-Mat. 2</template>
                </MenuItem>
            </PermissionComponent>
            <PermissionComponent type="reports" min="read">
                <MenuItem link="/report/shiftbook" tag="report.shiftbook">
                <template #icon>
                    <font-awesome-layers>
                        <font-awesome-icon icon="fa-solid fa-chart-simple" transform="grow-6" class="text-light" />
                        <font-awesome-icon icon="fa-solid fa-book" transform="shrink-2 down-4.2 right-11"
                            class="text-success" />
                    </font-awesome-layers>
                </template>
                <template #name>Schichtbücher</template>
                </MenuItem>
            </PermissionComponent>
            <PermissionComponent type="reports" min="read">
                <MenuItem link="/report/zaehler" tag="report.zaehler">
                <template #icon>
                    <font-awesome-layers>
                        <font-awesome-icon icon="fa-solid fa-chart-simple" transform="grow-6" class="text-light" />
                        <font-awesome-icon icon="fa-solid fa-stopwatch" transform="shrink-2 down-4.2 right-11"
                            class="text-danger" />
                    </font-awesome-layers>
                </template>
                <template #name>Zähler</template>
                </MenuItem>
            </PermissionComponent>
        </ul>
        <hr>
        <div class="dropdown">
            <span class="d-flex align-items-center text-white text-decoration-none pt-2"
                @click="layoutStore.toggleBottomMenu()" style="cursor: pointer;">
                <font-awesome-icon icon="fa-solid fa-user" />
                <strong class="ms-1">{{ currentUser.currentUser.code }}</strong>
            </span>
            <Transition>
                <BottomMenu v-if="layoutStore.isBottomMenuSidebarActive" />
            </Transition>
        </div>
    </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>