<script setup>
import { computed } from 'vue';
import { formatDate } from '@/lib/util/formatter.js'

import { RouterLink } from 'vue-router';

const props = defineProps({
    asset: {},
    display: {
        default: 'text'
    }
});

const wrappedAsset = computed(() => {
    if (props.asset == null)
        return {
            id: 0,
            enabled: false,
            display_id: 'Lade...',
            at_date: null,
            customer: {
                name: "Lade..."
            }
        }
    return props.asset;
});
</script>

<template>
    <PermissionComponent type="orders" min="read">
        <template #default>
            <RouterLink :to="'/order/' + wrappedAsset.id" class="text-decoration-none">
                <template v-if="display == 'text'">
                    <font-awesome-icon icon="fa-solid fa-shopping-cart" class="me-1" />
                    {{ wrappedAsset.display_id }}
                </template>
                <template v-else-if="display == 'card'">
                    <div class="card">
                        <div class="card-body">
                            <strong class="d-block">
                                <font-awesome-layers>
                                    <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" class="me-1" />
                                </font-awesome-layers>
                                {{ wrappedAsset.display_id }}
                            </strong>
                            <p class="mb-0" v-if="wrappedAsset.at_date != null">
                                Auftrag vom {{ formatDate(wrappedAsset.at_date) }}, Kunde: {{ wrappedAsset.customer.name }}
                            </p>
                            <p class="mb-0" v-else>
                                Kunde: {{ wrappedAsset.customer.name }}
                            </p>
                        </div>
                    </div>
                </template>
            </RouterLink>
        </template>
        <template #cant>
            <template v-if="display == 'text'">
                <font-awesome-icon icon="fa-solid fa-arrow-from-right-bracket" />
                <span :class="{ 'text-muted': wrappedAsset.enabled == false }">{{ wrappedAsset.display_id }}</span>
            </template>
            <template v-else-if="display == 'card'">
                <div class="card">
                    <div class="card-body" :class="{ 'text-muted': wrappedAsset.enabled == false }">
                        <strong class="d-block">
                            <font-awesome-icon icon="fa-solid fa-arrow-from-right-bracket" />
                            {{ wrappedAsset.display_id }}
                        </strong>
                        <p class="mb-0" v-if="wrappedAsset.at_date != null">
                            Auftrag vom {{ formatDate(wrappedAsset.at_date) }}, Kunde: {{ wrappedAsset.customer.name }}
                        </p>
                        <p class="mb-0" v-else>
                            Kunde: {{ wrappedAsset.customer.name }}
                        </p>
                    </div>
                </div>
            </template>
        </template>
    </PermissionComponent>
</template>