<script setup>
import { ref, computed } from 'vue';

import { useToast } from "vue-toastification";
const toast = useToast();

import { useCurrentUserStore } from '@/stores/currentUser';
const currentUserStore = useCurrentUserStore();

const theUserName = ref('');
const thePassword = ref('');

const isLoading = ref(false);

import AuthAPI from '@/lib/api/auth.js';
import UserAPI from '@/lib/api/user.js';

import { useServersideOptionsStore } from '@/stores/serversideOptions';
const currentSSOStore = useServersideOptionsStore();

import { useWeightTaraStore } from '@/stores/weightTara.js';
const weightTaraStore = useWeightTaraStore();

const attemptLogin = async () => {
    isLoading.value = true;
    try {
        // @login
        const loginInfo = await AuthAPI.Login(theUserName.value, thePassword.value);
        currentUserStore.currentSessionToken = loginInfo.data.token;
        currentUserStore.currentUser = loginInfo.data.user;

        currentSSOStore.setOptions((await UserAPI.LoadProperties()).data);
        await weightTaraStore.loadTaras();
    } catch (e) {
        var theErrorMessage = null;
        if (e.response && e.response.data && e.response.data.message) {
            if (e.response.data.message == "Username or password wrong!")
                theErrorMessage = "Benutzername oder Kennwort ungültig!";
            else
                theErrorMessage = e.response.data.message;
        } else {
            theErrorMessage = e.message;
        }
        toast.error(theErrorMessage, {
            position: "bottom-center",
            timeout: 2500,
            closeOnClick: true,
        });
    }
    isLoading.value = false;
};
</script>
<template>
    <section class="vw-100 vh-100 d-flex flex-column">
        <div class="container-fluid flex-fill">
            <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="d-none d-lg-block col-md-9 col-lg-6 col-xl-5">

                </div>
                <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1 bg-white the-login-box">
                    <div class="d-flex align-items-center the-header">
                        <img src="@/assets/images/logo.webp" />
                        <h1 class="ms-3">pfr-db.de</h1>
                    </div>

                    <form @submit.prevent="attemptLogin">
                        <div class="form-outline mb-4">
                            <input type="text" class="form-control form-control-lg" placeholder="mustermann"
                                v-model="theUserName" :disabled="isLoading" :class="{ 'disabled': isLoading }" />
                            <label class="form-label" for="form3Example3">Benutzername</label>
                        </div>

                        <div class="form-outline mb-3">
                            <input type="password" class="form-control form-control-lg" placeholder="*****"
                                v-model="thePassword" :disabled="isLoading" :class="{ 'disabled': isLoading }" />
                            <label class="form-label">Kennwort</label>
                        </div>

                        <div class="text-center text-lg-start mt-4 pt-2">
                            <button type="submit" class="btn btn-primary btn-lg" :disabled="isLoading"
                                :class="{ 'disabled': isLoading }" @click.prevent="attemptLogin" role="submit">
                                <span class="me-2">
                                    <font-awesome-icon icon="fa-solid fa-spinner" spin-pulse v-if="isLoading" />
                                </span>
                                Login
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
        <div
            class="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
            <div class="text-white mb-3 mb-md-0">
                Copyright © 2023 PFR Nord GmbH
            </div>

            <div>
                <a href="https://www.pfr-nord.de/" class="text-white text-decoration-none">
                    www.pfr-nord.de
                </a>
            </div>
            <!-- Right -->
        </div>
    </section>
</template>
<style scoped>
.the-login-box {
    padding: 2.5rem;
    border-radius: 1rem;
}

.the-login-box .the-header {
    padding-bottom: 2.5rem;
}

img {
    max-height: 4rem;
}

.flex-fill {
    background-image: url('@/assets/images/login-bg-tile.png');
    background-repeat: repeat;
    background-position: center center;
    background-size: 256px;
}

button {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}
</style>