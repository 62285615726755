import { ref, watch } from 'vue';
import { defineStore } from 'pinia';

export const useLayoutStore = defineStore('layout', () => {
    const isMobileSidebarActive = ref(false)
    const isBottomMenuSidebarActive = ref(false);

    function toggleMobileSidebar() {
        isMobileSidebarActive.value = !isMobileSidebarActive.value;
    }

    watch(isMobileSidebarActive, (to) => {
        if (to) document.body.classList.add('sidebar-open');
        else document.body.classList.remove('sidebar-open');
    });

    function toggleBottomMenu() {
        isBottomMenuSidebarActive.value = !isBottomMenuSidebarActive.value;
    }

    const breadcrumbs = ref(null);
    function setBreadcrumbs(to) {
        breadcrumbs.value = to;
    }

    watch(breadcrumbs, () => {
        if (breadcrumbs.value.length == 0) {
            const pageTitle ="www.pfr-db.de";
            document.title = pageTitle;
        } else if (breadcrumbs.value.length == 1) {
            const pageTitle = breadcrumbs.value[breadcrumbs.value.length - 1].name + " - www.pfr-db.de";
            document.title = pageTitle;
        } else if (breadcrumbs.value.length > 1) {
            const pageTitle = breadcrumbs.value[breadcrumbs.value.length - 1].name +" - "+ breadcrumbs.value[breadcrumbs.value.length - 2].name + " - www.pfr-db.de";
            document.title = pageTitle;
        }
    });

    const activePage = ref(null);
    function setActivePage(to) {
        activePage.value = to;
    }

    const isSearchVisible = ref(false);

    return {
        isMobileSidebarActive,
        isBottomMenuSidebarActive,
        toggleMobileSidebar,
        toggleBottomMenu,
        breadcrumbs,
        setBreadcrumbs,
        activePage,
        setActivePage,
        isSearchVisible,
    }
})
