<script setup>
import { useLayoutStore } from '@/stores/layout.js';

const layoutStore = useLayoutStore();
layoutStore.setBreadcrumbs([
    {
        name: "Fehler"
    },
    {
        name: "404"
    }
]);
layoutStore.setActivePage("");
</script>
<template>
    <div class="container">
        <h1>404</h1>
        <p>Die Seite existiert nicht.</p>
    </div>
</template>

<style scoped>
.container {
    margin-top: 100px;
}

@media screen and (min-height: 400px) {
    .container {
        margin-top: 180px;
    }
}

@media screen and (min-height: 800px) {
    .container {
        margin-top: 280px;
    }
}
</style>