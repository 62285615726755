<script setup>
import { computed } from 'vue';
const props = defineProps(['type', 'min']);

import { translateIntegerToLevel, translateLevelToInteger } from '@/lib/permission.js';

import { useCurrentUserStore } from '@/stores/currentUser';
const currentUser = useCurrentUserStore();

const readablePermissionName = computed(() => {
    switch(props.type) {
        case "bigbags": return "Bigbags";
        case "components": return "Komponenten";
        case "customers": return "Kunden";
        case "inbound": return "Materialeingang";
        case "material": return "Material";
        case "orders": return "Aufträge";
        case "parkingspace": return "Materiallager";
        case "picklists": return "Picklisten";
        case "shiftbook": return "Schichtbuch";
        case "storagelocation": return "Verkaufslager";
        case "users": return "Benutzer";
        case "reports": return "Report";
        case "reports_presence": return "Report Anwesenheit";
        default: return "perm["+ props.type +"]";
    }
});

const currentPermissionLevel = computed(() => {
    if (currentUser.currentUser == null) return 0;
    if (typeof currentUser.currentUser.permissions[props.type] == "undefined") return 0;
    return translateLevelToInteger(currentUser.currentUser.permissions[props.type]);
});

const requiredPermissionLevel = computed(() => {
    return translateLevelToInteger(props.min);
});


import { useToast } from "vue-toastification";
const toast = useToast();
const alertPermission = () => {
    toast.error(
        "Fehlende Berechtigung " + readablePermissionName.value + "\n"
        + "Mindestens: " + translateIntegerToLevel(requiredPermissionLevel.value) + ", aktuell: " + translateIntegerToLevel(currentPermissionLevel.value),
        {
            position: "bottom-center",
            timeout: 2500,
            closeOnClick: true,
        }
    );
};
</script>
<template>
    <template v-if="currentPermissionLevel >= requiredPermissionLevel">
        <slot>No content</slot>
    </template>
    <template v-else-if="currentUser.debugEnabled">
        <slot name="cant"></slot>
        <button class="btn btn-sm btn-danger ms-1 me-1" @click.prevent="alertPermission()">
            <font-awesome-icon icon="fa-solid fa-xmark" />
        </button>
    </template>
    <template v-else>
        <slot name="cant"></slot>
    </template>
</template>

<style scoped>
button.btn {
    animation: blinker 2s ease-in-out infinite;
}

@keyframes blinker {
    50% {
        opacity: 0.3;
    }
}
</style>