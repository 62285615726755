<script setup>
import { useLayoutStore } from '@/stores/layout';
const layoutStore = useLayoutStore();

import { useWeightTaraStore } from '@/stores/weightTara.js';
const weightTaraStore = useWeightTaraStore();
</script>
<template>
    <nav class="navbar navbar-dark bg-dark row">
        <div class="container-fluid">
            <button class="navbar-toggler" @click.prevent="layoutStore.toggleMobileSidebar()">
                <font-awesome-icon icon="fa-solid fa-bars" />
            </button>
            <span class="ms-auto navbar-brand" style="cursor: pointer;"
                @click.prevent="layoutStore.isSearchVisible = true">
                <font-awesome-icon class="" icon="fa-solid fa-search" />
            </span>
            <span class="navbar-brand" style="cursor: pointer;" @click.prevent="weightTaraStore.startTara()">
                <font-awesome-icon class="" icon="fa-solid fa-weight-scale" />
            </span>
            <span class="navbar-brand d-none d-md-block">pfr-db.de</span>
            <div class="collapse navbar-collapse" id="navbarColor01">
                <!--<ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#">Home</a>
                    </li>
                        <li class="nav-item">
                                <a class="nav-link" href="#">Features</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">Pricing</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">About</a>
                            </li>
                        </ul>
                        <form class="d-flex">
                            <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                            <button class="btn btn-outline-light" type="submit">Search</button>
                        </form>-->
            </div>
        </div>
    </nav>
</template>

<style scoped></style>