import raw from "./raw"

export default {
    /**
     * Liest alle Inbounds via API aus
     * 
     * @param {object} sort Sortier- und Pagination-Properties
     * @param {number} sort.offset Das Offset
     * @param {number} sort.count Anzahl
     * @param {number} sort.sortby Feld, nach dem sortiert werden soll
     * @param {number} sort.sortdir Richtung, in die sortiert werden soll
     * @param {object?} filter
     * @returns {Promise<object>}
     */
    async GetAll(sort, filter) {
        sort = { ...sort };
        var getParams = (new URLSearchParams(sort)).toString();
        if (typeof filter == "undefined") filter = {};
        return raw.get('/v1/inbound?' + getParams + '&filter=' + encodeURIComponent(JSON.stringify(filter)));
    },

    /**
     * Liest Summe der Inbounds aus
     * 
     * @param {object?} filter
     * @returns {Promise<object>}
     */
    async GetAllSum(filter) {
        if (typeof filter == "undefined") filter = {};
        return raw.get('/v1/inboundsum?filter=' + encodeURIComponent(JSON.stringify(filter)));
    },

    /**
     * Liest einen Inbound via ID aus
     * 
     * @param {number} id
     * @returns {Promise<object>}
     */
    async ByID(id) {
        return raw.get('/v1/inbound/' + id);
    },

    /**
     * Sucht eine ID für einen aktivierten Inbound nach
     * dessen display_id
     * @param {string} display_id
     * @returns {Promise<object>}
     */
    async ValidateByDisplayID(display_id) {
        return raw.get('/v1/inbound/validate/' + display_id);
    },

    /**
     * Löscht einen Inbound
     * 
     * @param {number} id
     * @returns {Promise<object>}
     */
    async Delete(id, force) {
        if (typeof force == "undefined") force = false;
        return raw.delete('/v1/inbound/' + id + (force ? "?force=true" : ""));
    },


    /**
     * Liest alle offenen Inbounds via API aus
     * 
     * @returns {Promise<object>}
     */
    async GetOpen() {
        return raw.get('/v1/inbound/open');
    },

    /**
     * Wandelt einen Inbound zu erledigt um
     * @param {integer} inboundId
     * @returns {Promise<object>}
     */
    async ConvertToDone(inboundId) {
        return raw.post('/v1/inbound/' + inboundId + '/finish');
    },


    /**
     * Erstellt einen Inbound
     * 
     * @param {object} properties
     * @param {string} properties.order 
     * @param {number} properties.weight_gross
     * @param {number} properties.weight_net
     * @param {number} properties.weight_tara
     * @param {number} properties.balls
     * @param {number} properties.material_id
     * @param {string} properties.weightingslip
     * @param {string} properties.rona_id
     * @param {boolean} properties.has_claim
     * @param {boolean} properties.has_foreign_substances
     * @param {boolean} properties.has_pollution
     * @param {boolean} properties.has_can
     * @param {boolean} properties.has_foil
     * @param {string} properties.type
     * @param {integer} properties.unloading_parkingspace_id
     * @param {integer} properties.unloading_movement_id
     * @param {string} properties.unloading_state
     * @param {Date} properties.at
     * @param {string} properties.comment
     * @returns {Promise<object>}
     */
    async Create(properties) {
        properties.weight_gross = Math.round(properties.weight_gross);
        properties.weight_net = Math.round(properties.weight_net);
        properties.weight_tara = Math.round(properties.weight_tara);
        properties.balls = Math.round(properties.balls);
        properties.material_id = Math.round(properties.material_id);
        return raw.post('/v1/inbound', properties);
    },


    /**
     * Aktualisiert einen inbound
     * 
     * @param {number} id
     * @param {object} properties
     * @param {string} properties.order 
     * @param {number} properties.weight_gross
     * @param {number} properties.weight_net
     * @param {number} properties.weight_tara
     * @param {number} properties.balls
     * @param {number} properties.material_id
     * @param {string} properties.weightingslip
     * @param {string} properties.rona_id
     * @param {boolean} properties.has_claim
     * @param {boolean} properties.has_foreign_substances
     * @param {boolean} properties.has_pollution
     * @param {boolean} properties.has_can
     * @param {boolean} properties.has_foil
     * @param {string} properties.type
     * @param {integer} properties.unloading_parkingspace_id
     * @param {integer} properties.unloading_movement_id
     * @param {string} properties.unloading_state
     * @param {Date} properties.at
     * @param {string} properties.comment
     * @returns {Promise<object>}
     */
    async Update(id, properties) {
        properties.weight_gross = Math.round(properties.weight_gross);
        properties.weight_net = Math.round(properties.weight_net);
        properties.weight_tara = Math.round(properties.weight_tara);
        properties.balls = Math.round(properties.balls);
        return raw.put('/v1/inbound/' + id, properties);
    },

}