import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useEditStore = defineStore('edit', () => {
    const editStorageLocationId = ref(null);

    function createStorageLocation() {
        editStorageLocationId.value = 0;
    }

    function editStorageLocation(id) {
        editStorageLocationId.value = id;
    }

    const editParkingSpaceId = ref(null);

    function createParkingSpace() {
        editParkingSpaceId.value = 0;
    }

    function editParkingSpace(id) {
        editParkingSpaceId.value = id;
    }

    const editingBigbagId = ref(null);

    function createBigbag() {
        editingBigbagId.value = 0;
    }

    function editBigbag(id) {
        editingBigbagId.value = id;
    }

    const addingLabResultToBigbag = ref(null);
    function addLabResultToBigbag(bb) {
        addingLabResultToBigbag.value = bb;
    }

    const editMaterialOnParkingspaceId = ref(null);
    function editMaterialOnParkingspace(parkingspace, materialId) {
        if (parkingspace == null) {
            editMaterialOnParkingspaceId.value = null;
            return;
        }
        editMaterialOnParkingspaceId.value = {
            parkingspace,
            materialId
        };
    }

    const editingCustomerId = ref(null);
    function createCustomer() {
        editingCustomerId.value = 0;
    }

    function editCustomer(id) {
        editingCustomerId.value = id;
    }

    const addFilterListEntryId = ref(null);
    function addFilterListEntry(listType, listId, preset) {
        if (listType == null) {
            addFilterListEntryId.value = null;
            return;
        }

        if (typeof preset == "undefined") preset = {};
        addFilterListEntryId.value = {
            listType,
            listId,
            preset,
        };
    }

    const editingPicklistId = ref(null);

    function createPicklist(Type, CustomerID) {
        if (Type == null) {
            editingPicklistId.value = null;
            return;
        }

        editingPicklistId.value = {
            type: Type,
            customer_id: CustomerID,
            action: 'create'
        }
    }

    const editingInboundId = ref(null);

    function createInbound() {
        editingInboundId.value = 0;
    }

    function editInbound(id) {
        editingInboundId.value = id;
    }

    const editingOrderId = ref(null);

    function createOrder(customerId) {
        editingOrderId.value = {
            id: 0,
            customerId: customerId
        };
    }

    function editOrder(id, customerId) {
        if (id == null) {
            editingOrderId.value = null;
            return;
        }
        editingOrderId.value = {
            id: id,
            customerId: customerId
        };
    }

    const editingMaterialId = ref(null);

    function createMaterial() {
        editingMaterialId.value = 0;
    }

    function editMaterial(id) {
        editingMaterialId.value = id;
    }

    const editingShiftbookId = ref(null);

    function createShiftbook() {
        editingShiftbookId.value = 0;
    }

    function editShiftbook(id) {
        editingShiftbookId.value = id;
    }

    const editingShiftmemberId = ref(null);

    function addShiftMember(shiftbookId) {
        editingShiftmemberId.value = {
            id: 0,
            shift_id: shiftbookId,
        };
    }

    function editShiftmember(id, shiftbookId, personId, presenceId, comment) {
        if (id == null) {
            editingShiftmemberId.value = null;
            return;
        }
        editingShiftmemberId.value = {
            id: id,
            shift_id: shiftbookId,
            person_id: personId,
            presence_id: presenceId,
            comment: comment,
        };
    }

    const editingShiftComponentId = ref(null);

    function addShiftComponent(shiftbookId) {
        editingShiftComponentId.value = {
            id: 0,
            shift_id: shiftbookId,
        };
    }

    function editShiftComponent(id, shiftbookId, component_id, start, end, is_failure, comment, is_production_failure) {
        if (id == null) {
            editingShiftComponentId.value = null;
            return;
        }
        editingShiftComponentId.value = {
            id: id,
            shift_id: shiftbookId,
            component_id,
            start: typeof start == "string" ? new Date(start) : start,
            end: typeof end == "string" ? new Date(end) : end,
            is_failure,
            comment,
            is_production_failure,
        };
    }

    const destroyingBigbagId = ref(null);

    function destroyBigbag(id) {
        destroyingBigbagId.value = id;
    }

    const editingShiftPersonId = ref(null);

    function createShiftPerson() {
        editingShiftPersonId.value = 0;
    }

    function editShiftPerson(id) {
        editingShiftPersonId.value = id;
    }

    const editingUserId = ref(null);

    function createUser() {
        editingUserId.value = 0;
    }

    function editUser(id) {
        editingUserId.value = id;
    }

    const editingComponentId = ref(null);

    function createComponent(parentId) {
        editingComponentId.value = {
            parentId,
            componentId: 0,
        };
    }

    function editComponent(parentId, componentId) {
        if (parentId == null)
            editingComponentId.value = null;
        else
            editingComponentId.value = {
                parentId,
                componentId,
            };
    }

    const attachingMaterialToOrderId = ref(null);

    function attachMaterialToOrder(orderId) {
        attachingMaterialToOrderId.value = orderId;
    }

    return {
        editStorageLocationId,
        createStorageLocation,
        editStorageLocation,
        editParkingSpaceId,
        createParkingSpace,
        editParkingSpace,
        editingBigbagId,
        createBigbag,
        editBigbag,
        addingLabResultToBigbag,
        addLabResultToBigbag,
        editMaterialOnParkingspaceId,
        editMaterialOnParkingspace,
        editingCustomerId,
        createCustomer,
        editCustomer,
        addFilterListEntryId,
        addFilterListEntry,
        editingPicklistId,
        createPicklist,
        editingInboundId,
        createInbound,
        editInbound,
        editingOrderId,
        createOrder,
        editOrder,
        editingMaterialId,
        createMaterial,
        editMaterial,
        editingShiftbookId,
        createShiftbook,
        editShiftbook,
        editingShiftmemberId,
        addShiftMember,
        editShiftmember,
        editingShiftComponentId,
        addShiftComponent,
        editShiftComponent,
        destroyingBigbagId,
        destroyBigbag,
        editingShiftPersonId,
        createShiftPerson,
        editShiftPerson,
        editingUserId,
        createUser,
        editUser,
        editingComponentId,
        createComponent,
        editComponent,
        attachingMaterialToOrderId,
        attachMaterialToOrder,
    }
});