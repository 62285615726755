<script setup>
import { RouterLink } from 'vue-router';

import { useLayoutStore } from '@/stores/layout.js';
const layoutStore = useLayoutStore();
</script>
<template>
    <div class="navbar navbar-light row navbar-sm">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item">
                    <RouterLink to="/">
                        <font-awesome-icon icon="fa-solid fa-house" />
                    </RouterLink>
                </li>
                <li v-if="layoutStore.breadcrumbs != null" v-for="(breadcrumb, index) in layoutStore.breadcrumbs" :key="index" class="breadcrumb-item" :class="{'active': index == layoutStore.breadcrumbs.length - 1}">
                    <template v-if="breadcrumb.link">
                        <RouterLink :to="breadcrumb.link">
                            {{ breadcrumb.name }}
                        </RouterLink>
                    </template>
                    <template v-else>
                        {{ breadcrumb.name }}
                    </template>
                </li>
            </ol>
        </nav>
    </div>
</template>