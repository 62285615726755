import raw from "./raw"

export default {
    /**
     * Gibt alle Benutzer zurück
     * 
     * @param {object} sort Sortier- und Pagination-Properties
     * @param {number} sort.offset Das Offset
     * @param {number} sort.count Anzahl
     * @param {number} sort.sortby Feld, nach dem sortiert werden soll
     * @param {number} sort.sortdir Richtung, in die sortiert werden soll
     * @returns {Promise<object>}
     */
    async GetAll(sort) {
        sort = { ...sort };
        var getParams = (new URLSearchParams(sort)).toString();
        return raw.get('/v1/user?' + getParams);
    },
    
    /**
     * Liest einen Benutzer nach dessen ID aus
     * 
     * @param {number} id
     * @returns {Promise<object>}
     */
    async ByID (id) {
        return raw.get('/v1/user/'+ id);
    },
    
    /**
     * Erstellt einen neuen Benutzer. Gibt ihn dann zurück.
     * 
     * @param {object} properties
     * @param {string} properties.code
     * @param {string} properties.first_name
     * @param {string} properties.last_name
     * @param {string} properties.permission_users
     * @param {string} properties.permission_storagelocation
     * @param {string} properties.permission_parkingspace
     * @param {string} properties.permission_material
     * @param {string} properties.permission_customers
     * @param {string} properties.permission_shiftbook
     * @param {string} properties.permission_picklists
     * @param {string} properties.permission_orders
     * @param {string} properties.permission_components
     * @param {string} properties.permission_inbound
     * @param {string} properties.permission_bigbags
     * @param {string} properties.password
     * @returns {Promise<object>}
     */
    async Create (properties) {
        return raw.post('/v1/user/', properties);
    },
    
    /**
     * Aktualisiert einen neuen Benutzer. Gibt ihn dann zurück.
     * 
     * @param {number} id
     * @param {object} properties
     * @param {string} properties.code
     * @param {string} properties.first_name
     * @param {string} properties.last_name
     * @param {string} properties.permission_users
     * @param {string} properties.permission_storagelocation
     * @param {string} properties.permission_parkingspace
     * @param {string} properties.permission_material
     * @param {string} properties.permission_customers
     * @param {string} properties.permission_shiftbook
     * @param {string} properties.permission_picklists
     * @param {string} properties.permission_orders
     * @param {string} properties.permission_components
     * @param {string} properties.permission_inbound
     * @param {string} properties.permission_bigbags
     * @param {string} properties.password
     * @returns {Promise<object>}
     */
    async Update (id, properties) {
        return raw.put('/v1/user/'+ id, properties);
    },

    /**
     * Lädt alle Properties von einem User
     * @returns {Promise<object>}
     */
    async LoadProperties () {
        return raw.get('/v1/user/me/props');
    },

    /**
     * Setzt eine Property
     * @returns {Promise<object>}
     */
    async SetProperty (key, value) {
        return raw.put('/v1/user/me/props', {
            key,
            value
        });
    },
}