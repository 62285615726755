const formatDate = function (isoString, dateOnly = false) {
    var d = Date.parse(isoString);
    d = new Date(d);

    d = [
        ("0" + d.getDate()).substr(-2),
        ("0" + (d.getMonth() + 1)).substr(-2),
        d.getFullYear(),
        ("0" + d.getHours()).substr(-2),
        ("0" + d.getMinutes()).substr(-2),
        ("0" + d.getSeconds()).substr(-2),
    ]
    return "" + d[0] + "." + d[1] + "." + d[2] + (dateOnly ? '' :  " " + d[3] + ":" + d[4] + ":" + d[5]);
};

const formatTime = function (isoString, dateOnly = false) {
    var d = Date.parse(isoString);
    d = new Date(d);

    d = [
        ("0" + d.getHours()).substr(-2),
        ("0" + d.getMinutes()).substr(-2),
        ("0" + d.getSeconds()).substr(-2),
    ]
    return d[0] + ":" + d[1] + ":" + d[2];
};

const formatWeight = function (int, formatUnit = 't') {
    const factor = formatUnit == 't' || formatUnit == 'auto' && int >= 1000 ? 1000 : 1;
    const unit = factor == 1000 ? ' T' : 'kg';
    return (int / factor).toLocaleString(undefined, { minimumFractionDigits: factor == 1000 ? 2 : 0 }) + unit;
};

const formatInteger = function (int) {
    return Math.round(int).toLocaleString(undefined);
};

const formatFloat = function (int, decimals) {
    if(typeof decimals == "undefined") decimals = 3;
    return int.toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
};

const formatDateDiff = function (start, end) {
    var ret = {
        h: 0,
        m: 0,
        s: 0,
    };
    var diff = (typeof end == "string" ? new Date(end) : end) - (typeof start == "string" ? new Date(start) : start);
    diff = Math.floor(diff / 1000);
    while(diff >= 60) {
        ret.m++;
        diff -= 60;
    }
    while(ret.m >= 60) {
        ret.h++;
        ret.m -= 60;
    }
    return (ret.h < 10 ? "0"+ ret.h : ret.h) +":"+ ("0"+ ret.m).substr(-2) +":"+ ("0"+ ret.s).substr(-2);
};

const formatMinutes = function (minutes) {
    var ret = {
        h: 0,
        m: minutes * 1,
    };
    while(ret.m >= 60) {
        ret.h++;
        ret.m -= 60;
    }
    return (ret.h < 10 ? "0"+ ret.h : ret.h) +":"+ ("0"+ ret.m).substr(-2);
};

const formatSeconds = function (diff) {
    var ret = {
        h: 0,
        m: 0,
        s: 0,
    };
    while(diff >= 60) {
        ret.m++;
        diff -= 60;
    }
    while(ret.m >= 60) {
        ret.h++;
        ret.m -= 60;
    }
    return (ret.h < 10 ? "0"+ ret.h : ret.h) +":"+ ("0"+ ret.m).substr(-2) +":"+ ("0"+ diff).substr(-2);
};

const escape = (str) => {
    return str.replace(/[\u00A0-\u9999<>\&]/g, i => '&#'+i.charCodeAt(0)+';');
}

const nl2br = (str) => {
    return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
}

export {
    formatDate,
    formatWeight,
    formatInteger,
    formatFloat,
    formatDateDiff,
    formatTime,
    escape,
    nl2br,
    formatMinutes,
    formatSeconds,
};