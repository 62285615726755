import raw from "./raw"

export default {
    /**
     * Liest alle Materialien via API aus
     * 
     * @returns {Promise<object>}
     */
    async GetMaterials() {
        return raw.get('/v1/guest/waage/get-materials');
    },

    /**
     * Liest alle Storagelocations via API aus
     * 
     * @returns {Promise<object>}
     */
    async GetStoragelocations() {
        return raw.get('/v1/guest/waage/get-storagelocation');
    },

    /**
     * Erstellt einen neuen Bigbag
     * 
     * @param {object} properties
     * @param {number} properties.weight
     * @param {number} properties.weight_date
     * @param {number} properties.material_to
     * @param {number?} properties.storagelocation_id
     * @param {number?} properties.source
     * @returns {Promise<object>}
     */
    async CreateBigbag(properties) {
        return raw.post('/v1/guest/waage', properties);
    },

    /**
     * Gibt die URL zu der PDF eines Bigbags zurück
     * @param {number} bigbag_id
     * @returns {string}
     */
    GetBigbagPDFURL(bigbag_id) {
        return raw.apiBaseURL + "/v1/guest/ws/" + bigbag_id;
    },

    /**
     * Liest alle StorageLocations via API aus
     * 
     * @param {object} sort Sortier- und Pagination-Properties
     * @param {number} sort.offset Das Offset
     * @param {number} sort.count Anzahl
     * @param {number} sort.sortby Feld, nach dem sortiert werden soll
     * @param {number} sort.sortdir Richtung, in die sortiert werden soll
     * @returns {Promise<object>}
     */
    async GetParkingspaces(sort) {
        var getParams = (new URLSearchParams(sort)).toString();
        return raw.get('/v1/guest/waage/get-parkingspace?' + getParams);
    },

    /**
     * Liest einen Parkingspace via ID aus
     * 
     * @param {number} id
     * @returns {Promise<object>}
     */
    async ParkingspaceByID(id) {
        return raw.get('/v1/guest/waage/get-parkingspace/' + id);
    },

    /**
     * Neue Aufgabe
     * @param {object} placement
     * @param {number} placement.parkingspace
     * @param {number} placement.material
     * @param {number} placement.type
     * @param {number} placement.weight
     * @param {number} placement.balls
     * @param {number} placement.haType
     */
    async placeMaterialToProduction(placement) {
        return raw.post('/v1/guest/produce', placement);
    }
}