<script setup>
import { ref, defineAsyncComponent } from 'vue';
import { RouterView } from 'vue-router';

import LoginComponent from '@/views/login/Main.vue';

import MainSidebarLayout from '@/components/layout/sidebar/Main.vue'
import MainTopbarLayout from '@/components/layout/topbar/Main.vue';
import Breadcrumbs from '@/components/layout/breadcrumbs/Breadcrumbs.vue';
import SearchComponent from '@/components/common/Search.vue';

import DebugComponent from '@/components/common/Debug.vue';

import { useCurrentUserStore } from '@/stores/currentUser';
const currentUserStore = useCurrentUserStore();

import { useLayoutStore } from '@/stores/layout';
const layoutStore = useLayoutStore();

import GuestComponent from '@/lib/guest.js';
const DisplayGuestComponent = ref(GuestComponent());

const EditThingsComponent = defineAsyncComponent(() =>
  import('@/components/common/entityHandling/Main.vue')
);
</script>

<template>
  <component v-if="DisplayGuestComponent != null" :is="DisplayGuestComponent" />
  <template v-else-if="currentUserStore.currentSessionToken != null">
    <MainSidebarLayout />

    <div class="container-fluid right-content pb-2">
      <MainTopbarLayout />
      <Breadcrumbs />
      <div class="container-fluid right-bottom pt-0" id="main">
        <RouterView />
      </div>
    </div>

    <EditThingsComponent />
  </template>
  <template v-else>
    <LoginComponent />
  </template>

  <DebugComponent />
  <SearchComponent v-if="layoutStore.isSearchVisible" />
</template>

<style scoped>
.container-fluid.right-content {
  overflow-y: scroll;
}

.container-fluid.right-bottom {
  padding-top: 1em;
}
</style>