<script setup>
import OpenInboundComponent from '@/components/pages/home/Inbounds.vue'
import OpenOrdersComponent from '@/components/pages/home/Orders.vue'
import LoadingOrdersComponent from '@/components/pages/home/LoadingOrders.vue'
import { useLayoutStore } from '@/stores/layout.js';

const layoutStore = useLayoutStore();
layoutStore.setBreadcrumbs([{
    name: "Startseite"
}]);
layoutStore.setActivePage("home");
</script>
<template>
    <OpenInboundComponent />

    <div class="row">
        <div class="col-12 col-md-6 mt-3">
            <RouterLink to="/move" class="btn btn-lg w-100 btn-outline-primary">
                <font-awesome-icon icon="fa-solid fa-arrows-up-to-line" class="me-1" />
                Neue Bewegung
                <font-awesome-icon icon="fa-solid fa-arrows-down-to-line" class="ms-1" />
            </RouterLink>
        </div>
        <div class="col-12 col-md-6 mt-3">
            <RouterLink to="/produce" class="btn btn-lg w-100 btn-outline-primary">
                Neue Aufgabe
                <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" class="ms-1" />
            </RouterLink>
        </div>
    </div>
    
    <LoadingOrdersComponent />
    
    <OpenOrdersComponent />
</template>