<script setup>
import StickyTableHeader from '@/lib/StickyHeader.js';
import { onMounted, onUnmounted, ref, watchEffect } from 'vue';

const tableRef = ref(null);
const tableCloneRef = ref(null);

const props = defineProps(['class', 'id'])

var stickyTable = null;
onMounted(() => {
    watchEffect((onCleanup) => {
        if (!tableRef.value && !tableCloneRef.value) return;
        if (stickyTable !== null) return;
        stickyTable = new StickyTableHeader(
            tableRef.value,
            tableCloneRef.value,
            { max: 0 },
        );
        onCleanup(() => {
            if (stickyTable === null) return;
            stickyTable.destroy();
        });
    });
});

// Neuerstellung der Kopfzeile bei Anpassung der Eigenschaften in der Sidebar
import { createEventStack } from 'se-bus';
const eventStack = createEventStack();
onMounted(() => {
    eventStack.on('ssostore.update', () => {
        if (stickyTable === null) return;
        stickyTable.destroy();
        stickyTable = new StickyTableHeader(
            tableRef.value,
            tableCloneRef.value,
            { max: 0 },
        );
    })
});
onUnmounted(() => {
    eventStack.unregister();
});
</script>

<template>
    <div class="container-fluid">
        <div class="table-container table-with-sticky-header">
            <table ref="tableRef" :class="class" :id="id">
                <slot />
            </table>
        </div>
        <div class="table-container table-with-sticky-header">
            <table ref="tableCloneRef" :class="class" class="table-clone" />
        </div>
    </div>
</template>

<style scoped>
.table-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.table-clone {
    table-layout: fixed;
}
</style>