const translateIntegerToLevel = (level) => {
    switch (level) {
        case 0: return 'keine';
        case 1: return 'lesen';
        case 2: return 'schreiben';
        case 3: return 'löschen';
        default: return '???';
    }
}

const translateLevelToInteger = (level) => {
    switch (level) {
        case 'read': return 1;
        case 'write': return 2;
        case 'delete': return 3;
        default: return 0;
    }
}

export {
    translateIntegerToLevel,
    translateLevelToInteger,
};