import raw from "./raw"

export default {
    /**
     * Versucht den Login
     * Gibt dann auch einen Token sowie den aktuellen Benutzer zurück
     * 
     * @param {string} username
     * @param {string} password
     * @returns {Promise<object>}
     */
    async Login(username, password) {
        return raw.post('/v1/auth/login', {
            username,
            password
        });
    },
    
    /**
     * Validiert die aktuelle Sitzung.
     * Gibt den Benutzer zurück
     * @returns {Promise<object>}
     */
    async Validate() {
        return raw.get('/v1/auth/validate');
    },
    
    /**
     * Ändert das Kennwort des Benutzers
     * @param {string} newPassword
     * @returns {Promise<object>}
     */
    async ChangePassword(newPassword) {
        return raw.post('/v1/auth/changepassword', {
            password: newPassword,
        });
    },
}