import raw from "./raw"

export default {
    /**
     * Aktuelle Taras lesen
     * @returns {Promise<object>}
     */
    async GetCurrentTara() {
        return raw.get('/v1/weight/tara');
    },

    /**
     * Produktion
     * @param {string} name
     * @returns {Promise<object>}
     */
    async GetScale(name) {
        return raw.get('/v1/weight/' + name);
    },

    /**
     * Setzen eines Taras
     * @param {string} scale
     * @param {number} to
     * @returns {Promise<object>}
     */
    async SetTara(scale, to) {
        return raw.put('/v1/weight/tara', {
            scale,
            tara: to * 1,
        });
    },
}