<script setup>
import { useLayoutStore } from '@/stores/layout';
const layoutStore = useLayoutStore();

const toggleSidebarFixed = () => {
    layoutStore.toggleMobileSidebar();
};
</script>

<template>
    <nav class="navbar navbar-dark bg-dark d-block hide-on-mobile-navigation">
        <div class="nav nav-pills d-block">
            <div class="nav-item d-block" @click.prevent="toggleSidebarFixed()">
                <div class="nav-link">
                    <span class="icon" :class="{'text-primary': layoutStore.isMobileSidebarActive}">
                        <font-awesome-icon icon="fa-solid fa-map-pin" />
                    </span>
                    <span class="name" :class="{'text-primary': layoutStore.isMobileSidebarActive}">
                        Anheften
                    </span>
                </div>
            </div>
        </div>
    </nav>
</template>

<style scoped> 
.nav-item {
     cursor: pointer;
 }
</style>