import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import AuthAPI from '@/lib/api/auth.js';

import { useToast } from 'vue-toastification';


export default async () => {
    const myUUID = uuidv4();
    const toast = useToast();

    var pw1 = null;
    var pw2 = null;
    var currentErrorMessage = null;

    const passwordTypeEvent = () => {
        currentErrorMessage = null;
        if (pw1.value.trim().length == 0) {
            currentErrorMessage = "Angabe eines neuen Kennwortes notwendig";
        } else if (pw1.value != pw2.value) {
            currentErrorMessage = "Die Kennwörter stimmen nicht überein";
        }
    };

    const loginForm = await Swal.fire({
        title: 'Kennwort ändern',
        html: `
          <input type="text" id="pw1-${myUUID}" class="swal2-input" placeholder="Neues Kennwort">
          <input type="text" id="pw2-${myUUID}" class="swal2-input" placeholder="Bestätigen">
          <div class="callout callout-danger mt-1" id="errormsg-${myUUID}" style="display: none;"></div>
        `,
        showCancelButton: true,
        confirmButtonText: 'Ändern',
        cancelButtonText: 'Abbrechen',
        focusConfirm: false,
        preConfirm: async () => {
            const popup = Swal.getPopup();
            pw1 = popup.querySelector(`#pw1-${myUUID}`);
            pw2 = popup.querySelector(`#pw2-${myUUID}`);

            const Password1Value = pw1.value;
            const Password2Value = pw2.value;

            if (Password1Value.trim().length == 0 || Password2Value.trim().length == 0) {
                return Swal.showValidationMessage(`Die Angabe eines neuen Kennwortes ist notwendig`);
            }
            if (Password1Value != Password2Value) {
                return Swal.showValidationMessage(`Die beiden Kennwörter stimmen nicht überein`);
            }

            var ret = null;
            try {
                ret = await AuthAPI.ChangePassword(Password1Value);
                if (!ret.data || !ret.data.success)
                    throw new Error("Unbekannt (success = false)");

                toast.success("Kennwort geändert", {
                    position: "bottom-center",
                    timeout: 2500,
                    closeOnClick: true,
                });
            } catch (e) {
                return Swal.showValidationMessage(`Fehler: ${e.message}`);
            }
        },
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
    })
};