<script setup>
const props = defineProps({
    charCount: {
        default: 10
    }
})

const randomText = () => {
    var result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    var counter = 0;
    while (counter < props.charCount) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
};
</script>

<template>
    <span class="placeholder">{{ randomText() }}</span>
</template>

<style scoped>
.placeholder {
    color: transparent;
    text-shadow: 0px 0px 6px #333;
}
</style>