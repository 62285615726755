<script setup>
import { computed } from 'vue';

import { RouterLink } from 'vue-router';

const props = defineProps({
    asset: {},
    icon: {
        default: true,
    },
    display: {
        default: 'text'
    }
});

const wrappedAsset = computed(() => {
    if (props.asset == null)
        return {
            id: 0,
            enabled: false,
            name: 'Lade...',
            description: 'Informationen zu diesem Material werden geladen'
        }
    return props.asset;
});
</script>

<template>
    <PermissionComponent type="material" min="read">
        <template #default>
            <RouterLink :to="'/material/' + wrappedAsset.id" class="text-decoration-none"
                :class="{ 'text-muted': wrappedAsset.enabled == false }">
                <template v-if="display == 'text'">
                    <font-awesome-layers class="me-1" v-if="icon">
                        <font-awesome-icon icon="fa-solid fa-bottle-water"
                            :class="{ 'text-secondary': wrappedAsset.enabled == false }"
                            :transform="wrappedAsset.enabled ? '' : 'shrink-1'" />
                        <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger"
                            v-if="wrappedAsset.enabled == false" transform="grow-6 right-2" />
                        <font-awesome-icon icon="fa-solid fa-hand" class="text-warning"
                            transform="shrink-4 down-4 right-4" v-if="asset.default_shiftbook_calculate !== 1" />
                        <font-awesome-icon icon="fa-solid fa-arrow-left" class="text-success"
                            transform="shrink-4 down-8 right-2" v-if="asset.default_shiftbook_calculate === 2" />
                        <font-awesome-icon icon="fa-solid fa-arrow-right" class="text-primary"
                            transform="shrink-4 down-8 right-2" v-if="asset.default_shiftbook_calculate === 0" />
                    </font-awesome-layers>

                    {{ wrappedAsset.name }}
                </template>
                <template v-else-if="display == 'card'">
                    <div class="card">
                        <div class="card-body">
                            <strong class="d-block">
                                <font-awesome-layers class="me-2">
                                    <font-awesome-icon icon="fa-solid fa-bottle-water"
                                        :class="{ 'text-secondary': wrappedAsset.enabled == false }"
                                        :transform="wrappedAsset.enabled ? '' : 'shrink-1'" />
                                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger"
                                        v-if="wrappedAsset.enabled == false" transform="grow-6 right-2" />
                                    <font-awesome-icon icon="fa-solid fa-hand" class="text-warning"
                                        transform="shrink-4 down-4 right-4"
                                        v-if="asset.default_shiftbook_calculate !== 1" />
                                    <font-awesome-icon icon="fa-solid fa-arrow-left" class="text-success"
                                        transform="shrink-4 down-8 right-2"
                                        v-if="asset.default_shiftbook_calculate === 2" />
                                    <font-awesome-icon icon="fa-solid fa-arrow-right" class="text-primary"
                                        transform="shrink-4 down-8 right-2"
                                        v-if="asset.default_shiftbook_calculate === 0" />
                                </font-awesome-layers>
                                {{ wrappedAsset.name }}
                            </strong>
                            <p class="mb-0">
                                {{ wrappedAsset.comment }}
                            </p>
                        </div>
                    </div>
                </template>
            </RouterLink>
        </template>
        <template #cant>
            <div class="text-decoration-none" :class="{ 'text-muted': wrappedAsset.enabled == false }">
                <template v-if="display == 'text'">
                    <font-awesome-layers class="me-1">
                        <font-awesome-icon icon="fa-solid fa-warehouse"
                            :class="{ 'text-secondary': wrappedAsset.enabled == false }"
                            :transform="wrappedAsset.enabled ? '' : 'shrink-1'" />
                        <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger"
                            v-if="wrappedAsset.enabled == false" transform="grow-6 right-2" />
                    </font-awesome-layers>
                    {{ wrappedAsset.name }}
                </template>
                <template v-else-if="display == 'card'">
                    <div class="card">
                        <div class="card-body">
                            <strong class="d-block">
                                <font-awesome-layers class="me-2">
                                    <font-awesome-icon icon="fa-solid fa-warehouse"
                                        :class="{ 'text-secondary': wrappedAsset.enabled == false }"
                                        :transform="wrappedAsset.enabled ? '' : 'shrink-1'" />
                                    <font-awesome-icon icon="fa-solid fa-xmark" class="text-danger"
                                        v-if="wrappedAsset.enabled == false" transform="grow-6 right-2" />
                                </font-awesome-layers>
                                {{ wrappedAsset.name }}
                            </strong>
                            <p class="mb-0">
                                {{ wrappedAsset.comment }}
                            </p>
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </PermissionComponent>
</template>