import { ref } from 'vue';
import { defineStore } from 'pinia';
import UserAPI from '@/lib/api/user.js';
import { useToast } from "vue-toastification";

export const useServersideOptionsStore = defineStore('serversideOptions', () => {
    const options = ref(null);
    const toast = useToast();

    function setOptions(serversideResponse) {
        return options.value = serversideResponse;
    }

    async function setOption(key, value) {
        if(options.value[key] == value) return;
        const currentValue = options.value[key];
        options.value[key] = value;
        try {
            options.value = (await UserAPI.SetProperty(key, value)).data;
        } catch(e) {
            options.value[key] = currentValue;
            toast.error("Fehler beim Ändern: "+ e, {
                position: "bottom-center",
                timeout: 2500,
                closeOnClick: true,
            });
        }
    }
    
    return {
        options,
        setOptions,
        setOption
    }
});