import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { translateLevelToInteger } from '@/lib/permission.js';

export const useCurrentUserStore = defineStore('current-user', () => {
    const currentSessionToken = ref(null);
    const lastActiveDate = ref(null);

    

    watch(currentSessionToken, (to) => {
        if (to != null) document.body.classList.add('loggedIn');
        else document.body.classList.remove('loggedIn');
    });

    const currentUser = ref(null);

    const debugEnabled = ref(false);

    if (typeof window.localStorage.token != "undefined") {
        try {
            const data = JSON.parse(window.localStorage.token);
            lastActiveDate.value = new Date(data.lastActive);
            if ((Date.now() - lastActiveDate.value.getTime()) < 30 * 60 * 1000) {
                currentSessionToken.value = data.value;
            }
        } catch (e) {
            currentSessionToken.value = null;
            lastActiveDate.value = null;
        }
    }

    const rewriteStorage = () => {
        if (currentSessionToken.value != null) {
            window.localStorage.token = JSON.stringify({
                value: currentSessionToken.value,
                lastActive: lastActiveDate.value,
            })
        } else {
            delete window.localStorage.token;
        }
    };

    setInterval(() => {
        lastActiveDate.value = new Date();
    });

    watch(currentSessionToken, function (newValue) {
        rewriteStorage();
    });

    watch(lastActiveDate, function (newValue) {
        rewriteStorage();
    });

    const hasPermission = (permissionName, minLevel) => {
        const requiredLevel = translateLevelToInteger(minLevel);
        const currentLevel = translateLevelToInteger(currentUser.value == null ? "" : currentUser.value.permissions[permissionName]);
        return currentLevel >= requiredLevel;
    };

    const getShiftbookMember = () => {
        if(currentUser.value == null) return null;
        if(currentUser.value.shiftbook_person == null) return null;
        return currentUser.value.shiftbook_person;
    };

    const logout = () => {
        currentSessionToken.value = null;
        delete window.localStorage.token;
        window.location.reload();
    }

    return {
        currentSessionToken,
        logout,
        currentUser,
        debugEnabled,
        hasPermission,
        getShiftbookMember,
    }
})
