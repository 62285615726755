<script setup>
import { RouterLink } from 'vue-router';

const props = defineProps(['asset']);

import { useServersideOptionsStore } from '@/stores/serversideOptions';
const currentSSOStore = useServersideOptionsStore();

const getPersonName = () => {
    if(currentSSOStore.options.layout_mode == 'small') {
        var ret = props.asset.first_name.substr(0, 1) +'. ';
        if(props.asset.last_name.length >= 10) {
            ret += props.asset.last_name.substr(0, 7) +'…';
        } else {
            ret += props.asset.last_name;
        }
        return ret;
    }
    return props.asset.first_name +' '+ props.asset.last_name;
};
</script>
<template>
    <PermissionComponent type="shiftbook_person" min="read">
        <template #default>
            <RouterLink :to="'/shiftbook/person/' + asset.id" class="text-decoration-none">
                <font-awesome-layers>
                    <font-awesome-icon icon="fa-solid fa-user" />
                </font-awesome-layers>

                {{ getPersonName() }}
            </RouterLink>
        </template>
        <template #cant>
            <font-awesome-layers>
                <font-awesome-icon icon="fa-solid fa-user" />
            </font-awesome-layers>

            {{ getPersonName() }}
        </template>
    </PermissionComponent>
</template>