import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import NotFoundView from '@/views/NotFound.vue';

import { useLayoutStore } from '@/stores/layout.js';
var layoutStore = null;

import { useCurrentUserStore } from '@/stores/currentUser.js';
var currentUserStore = null;
const getCurrentUserStore = () => {
  if(currentUserStore != null) return currentUserStore;
  currentUserStore = useCurrentUserStore();
  return currentUserStore;
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/storagelocation',
      name: 'storagelocation',
      component: () => import('@/views/storagelocation/ListPage.vue')
    },
    {
      path: '/storagelocation/:id(\\d+)',
      name: 'storagelocation-single',
      component: () => import('@/views/storagelocation/SinglePage.vue')
    },
    {
      path: '/parkingspace',
      name: 'parkingspace',
      component: () => import('@/views/parkingspace/ListPage.vue')
    },
    {
      path: '/parkingspace/:id(\\d+)',
      name: 'parkingspace-single',
      component: () => import('@/views/parkingspace/SinglePage.vue')
    },
    {
      path: '/bigbag',
      name: 'bigbag',
      component: () => import('@/views/bigbag/ListPage.vue')
    },
    {
      path: '/bigbag/:id(\\d+)',
      name: 'bigbag-single',
      component: () => import('@/views/bigbag/SinglePage.vue')
    },
    {
      path: '/customer',
      name: 'customer',
      component: () => import('@/views/customer/ListPage.vue')
    },
    {
      path: '/customer/:id(\\d+)',
      name: 'customer-single',
      component: () => import('@/views/customer/SinglePage.vue')
    },
    {
      path: '/move',
      name: 'move-material',
      component: () => import('@/views/production/MovePage.vue')
    },
    {
      path: '/produce',
      name: 'produce-material',
      component: () => import('@/views/production/ProducePage.vue')
    },
    {
      path: '/picklist/template/:id(\\d+)',
      name: 'picklist-template-single',
      component: () => import('@/views/picklist/TypeTemplatePage.vue')
    },
    {
      path: '/picklist/order/:id(\\d+)',
      name: 'picklist-order-single',
      component: () => import('@/views/picklist/TypeOrderPage.vue')
    },
    {
      path: '/order',
      name: 'order',
      component: () => import('@/views/order/ListPage.vue')
    },
    {
      path: '/order/:id(\\d+)',
      name: 'order-single',
      component: () => import('@/views/order/SinglePage.vue')
    },
    {
      path: '/inbound',
      name: 'inbound',
      component: () => import('@/views/inbound/ListPage.vue')
    },
    {
      path: '/inbound/:id(\\d+)',
      name: 'inbound-single',
      component: () => import('@/views/inbound/SinglePage.vue')
    },
    {
      path: '/material',
      name: 'material',
      component: () => import('@/views/material/ListPage.vue')
    },
    {
      path: '/material/:id(\\d+)',
      name: 'material-single',
      component: () => import('@/views/material/SinglePage.vue')
    },
    {
      path: '/component',
      name: 'component',
      component: () => import('@/views/component/ListPage.vue')
    },
    {
      path: '/shiftbook',
      name: 'shiftbook',
      component: () => import('@/views/shiftbook/ListPage.vue')
    },
    {
      path: '/shiftbook/:id(\\d+)',
      name: 'shiftbook-single',
      component: () => import('@/views/shiftbook/SinglePage.vue')
    },
    {
      path: '/shiftbook/person',
      name: 'shiftbook-person',
      component: () => import('@/views/shiftbook/person/ListPage.vue'),
      beforeEnter: (_to, _from, next) => {
        if(!getCurrentUserStore().hasPermission("shiftbook_person", "read")) next("/");
        else next();
      }
    },
    {
      path: '/shiftbook/person/:id(\\d+)',
      name: 'shiftbook-single-person',
      component: () => import('@/views/shiftbook/person/SinglePage.vue'),
      beforeEnter: (_to, _from, next) => {
        if(!getCurrentUserStore().hasPermission("shiftbook_person", "read")) next("/");
        else next();
      }
    },
    {
      path: '/user',
      name: 'user',
      component: () => import('@/views/user/ListPage.vue')
    },
    {
      path: '/user/:id(\\d+)',
      name: 'user-single',
      component: () => import('@/views/user/SinglePage.vue')
    },
    {
      path: '/maintenance/template',
      name: 'maintenance-template',
      component: () => import('@/views/maintenance/Template.vue')
    },
    {
      path: '/maintenance/:id(\\d+)',
      name: 'maintenance-single',
      component: () => import('@/views/maintenance/SinglePage.vue')
    },
    {
      path: '/report/member',
      name: 'report-member',
      component: () => import('@/views/report/ShiftbookMembers.vue')
    },
    {
      path: '/report/produce',
      name: 'report-produce',
      component: () => import('@/views/report/Produce.vue')
    },
    {
      path: '/report/bigbag',
      name: 'report-bigbag',
      component: () => import('@/views/report/Bigbag.vue')
    },
    {
      path: '/report/bigbag_v2',
      name: 'report-bigbag.v2',
      component: () => import('@/views/report/BigbagV2.vue')
    },
    {
      path: '/report/shiftbook',
      name: 'report-shiftbook',
      component: () => import('@/views/report/Shiftbook.vue')
    },
    {
      path: '/report/inventory',
      name: 'report-inventory',
      component: () => import('@/views/report/Inventory.vue')
    },
    {
      path: '/report/annualoverview',
      name: 'report-annualoverview',
      component: () => import('@/views/report/AnnualOverview.vue')
    },
    {
      path: '/report/zaehler',
      name: 'report-zaehler',
      component: () => import('@/views/report/Zaehler.vue')
    },
    {
      path: '/report/allocation',
      name: 'report-allocation',
      component: () => import('@/views/report/Allocation.vue')
    },
    {
      path: '/report/sync/flakeanalyser',
      name: 'report-sync-flakeanalyser',
      component: () => import('@/views/report/sync/Flakeanalyser.vue')
    },
    {
      path: '/:catchAll(.*)',
      name: 'not-found',
      component: NotFoundView
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (layoutStore == null) layoutStore = useLayoutStore();
  if (window.innerWidth < 1204) {
    layoutStore.isMobileSidebarActive = false;
  }
  layoutStore.isSearchVisible = false;
  next();
});

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes("Importing a module script failed")) {
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location = to.fullPath;
    }
  }
});


export default router
