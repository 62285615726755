<script setup>
import { useServersideOptionsStore } from '@/stores/serversideOptions';
const currentSSOStore = useServersideOptionsStore();
</script>
<template>
    <template v-if="currentSSOStore.options.layout_mode == 'small'">
        <slot name="short"></slot>
    </template>
    <template v-else>
        <slot name="long"></slot>
    </template>
</template>