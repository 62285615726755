<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { createEventStack } from 'se-bus';
import { formatDate } from '@/lib/util/formatter.js';

import Table from '@/components/common/Table.vue';

import LoadingNumberComponent from '@/components/loading/Number.vue';

import PicklistLink from '@/components/common/assets/Picklist.vue';
import OrderLink from '@/components/common/assets/Order.vue';

import PicklistAPI from '@/lib/api/picklist.js';

import { useEditStore } from '@/stores/edit.js';
const editStore = useEditStore();

const tableData = ref({
    data: null,
    error: null,
    sort: {
        by: 'id',
        dir: 'desc'
    },
    fields: [
        {
            key: "id",
            display_name: "ID",
            valign: 'middle',
            sortable: true,
            hide_mobile: true,
        },
        {
            key: "picklist",
            display_name: "Pickliste",
            valign: 'middle',
            sortable: false,
        },
        {
            key: "order",
            display_name: "Auftrag",
            valign: 'middle',
            sortable: false,
            hide_mobile: true,
        },
        {
            key: "license_plate",
            display_name: "Kennzeichen",
            valign: 'middle',
            sortable: false,
        },
        {
            key: "at",
            display_name: "Ladedatum",
            align: 'end',
            valign: 'middle',
            sortable: true,
        },
        {
            key: "bigbags",
            display_name: "Bigbags verladen",
            align: 'end',
            valign: 'middle',
            sortable: false
        },
    ],
    pagination: {
        offset: 0,
        max: 0,
        perPage: 15
    }
});

const changeSort = (newSort) => {
    tableData.value.error = null;
    tableData.value.sort = newSort;
    reload();
};

const reload = async () => {
    tableData.value.data = null;
    try {
        var s = await PicklistAPI.Order.ByNotFullyLoadedState();
        for(var i = 0; i < s.data.length; i++)
            s.data[i].at = s.data[i].picklist.pickup_date == null ? null : new Date(s.data[i].picklist.pickup_date).getTime();

        tableData.value.data = s.data;
        tableData.value.pagination.offset = 0;
        tableData.value.pagination.max = s.data.length;
        tableData.value.pagination.perPage = s.data.length + 1;
    } catch (error) {
        setTimeout(() => {
            tableData.value.error = error.message;
        }, 650)
    }
};

const changeOffset = (newOffset) => {
    tableData.value.error = null;
    tableData.value.pagination.offset = newOffset.offset;
    reload();
};

reload();

const eventStack = createEventStack();
onMounted(() => {
    eventStack.on('inbound.edit', (s) => reload());
});

onUnmounted(() => {
    eventStack.unregister();
});
</script>
<template>
    <div class="card mt-3">
        <div class="card-header">
            <strong>Zu ladende Aufträge</strong>
            <small class="d-block">Pickliste ist in einem Auftrag, alle Positionen in der Pickliste gepickt, aber noch nicht alle geladen.</small>
        </div>
        <div class="card-body p-0">
            <Table id="inboundOverview" :fields="tableData.fields" :data="tableData.data" :sort="tableData.sort"
                :pagination="tableData.pagination" :displayError="tableData.error" @changeSort="changeSort"
                @changeOffset="changeOffset" class="mb-0">
                <template #id="attrs">
                    {{ attrs.entry.picklist.id }}
                </template>
                <template #picklist="attrs">
                    <PicklistLink :asset="attrs.entry.picklist" />
                </template>
                <template #license_plate="attrs">
                    <span v-if="attrs.entry.picklist.order.license_plate != ''">{{ attrs.entry.picklist.order.license_plate }}</span>
                    <span v-else>-</span>
                </template>
                <template #order="attrs">
                    <OrderLink :asset="attrs.entry.picklist.order" />
                </template>
                <template #at="attrs">
                    <span v-if="attrs.entry.picklist.order.pickup_date != null">{{ formatDate(attrs.entry.picklist.order.pickup_date, true) }}</span>
                    <span v-else>-</span>
                </template>
                <template #bigbags="attrs">
                    {{ attrs.entry.bigbags.loaded }} / {{ attrs.entry.bigbags.overall }}
                </template>
                <!--
                <template #actions="attrs">
                    <PermissionComponent type="orders" min="write">
                        <span style="cursor: pointer;" @click="editStore.editCustomer(attrs.entry.id)">
                            <font-awesome-icon icon="fa-solid fa-pencil" />
                        </span>
                    </PermissionComponent>
                </template>
                -->
            </Table>
        </div>
        <div class="card-footer text-end">
            <button class="btn btn-outline-primary" @click="reload()">
                Aktualisieren
            </button>
        </div>
    </div>
</template>