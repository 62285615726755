<script setup>
import { computed } from 'vue';
import { RouterLink } from 'vue-router';

const props = defineProps(['link', 'tag']);


import { useLayoutStore } from '@/stores/layout.js';
const layoutStore = useLayoutStore();

const getClasses = computed(() => {
    if(layoutStore.activePage == props.tag)
        return 'active text-white bg-primary';
    else return '';
});
</script>
<template>
    <li class="nav-item">
        <RouterLink :to="link" class="nav-link" aria-current="page" :class="getClasses">
            <span class="icon">
                <slot name="icon">
                    <font-awesome-icon icon="fa-solid fa-user-secret" />
                </slot>
            </span>
            <span class="name" :class="getClasses">
                <slot name="name">
                    Start
                </slot>
            </span>

        </RouterLink>
    </li>
</template>