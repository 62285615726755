<script setup>
import { computed, ref, watch } from 'vue';

import BlankTextComponent from '@/components/loading/Text.vue';


const props = defineProps([
    'id',
    'sort',
    'fields',
    'data',
    'pagination',
    'displayError',
    'class',
]);

const emit = defineEmits([
    'changeSort',
    'changeOffset',
]);



const hasPages = computed(() => {
    if (props.data == null) return false;
    if (props.data.length == 0) return false;
    return countPages.value >= 2;
    return true;
})

const isFirstPage = computed(() => {
    return currentPage.value == 1;
});

const isLastPage = computed(() => {
    return currentPage.value == countPages.value;
});

const countPages = computed(() => {
    if (props.data == null) return 0;
    if (props.data.length == 0) return 0;
    return Math.floor(props.pagination.max / props.pagination.perPage) + 1;
});

const currentPage = computed(() => {
    if (props.data == null) return 1;
    if (props.data.length == 0) return 1;
    return Math.floor(props.pagination.offset / props.pagination.perPage) + 1;
});

const changeSort = (field) => {
    if (!field.sortable) return;
    if (props.sort.by == field.key) {
        if (props.sort.dir == 'asc') emit('changeSort', { by: field.key, dir: 'desc' });
        else emit('changeSort', { by: field.key, dir: 'asc' });
    }
    else emit('changeSort', { by: field.key, dir: 'asc' });
};

const changePage = (index) => {
    emit('changeOffset', { offset: (index - 1) * props.pagination.perPage });
};

const loadingBlurredFields = ref(0);

watch(props, (newValue) => {
    if (newValue == null) return;
    if (newValue.data == null) return;
    loadingBlurredFields.value = newValue.data.length;
    return;
});

const currentLoadingSimulatedRows = computed(() => {
    if (loadingBlurredFields.value != 0) return loadingBlurredFields.value;
    if (props.pagination && props.pagination.perPage) return loadingBlurredFields.value;
    return 5;
});
</script>

<template>
    <div class="table-responsive">
        <table class="table table-striped" :class="class">
            <thead>
                <tr>
                    <th v-for="field in fields" :key="field.key" @click="changeSort(field)"
                        :class="{ 'text-end': field.align == 'end', 'd-none d-lg-table-cell': field.hide_mobile }">
                        {{ field.display_name }}
                        <template v-if="sort.by == field.key">
                            <span v-if="sort.dir == 'asc'" class="ms-2">
                                <font-awesome-icon icon="fa-solid fa-arrow-up" />
                            </span>
                            <span v-else class="ms-2">
                                <font-awesome-icon icon="fa-solid fa-arrow-down" />
                            </span>
                        </template>
                    </th>
                </tr>
            </thead>

            <!-- Fehler anzeigen? -->
            <tbody v-if="displayError != null">
                <tr>
                    <td colspan="100%" class="text-danger p-4">
                        <div class="d-flex justify-content-center align-middle">
                            <div class="icon pe-3 display-6 ">
                                <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
                            </div>
                            <div class="message">
                                <p><strong>Fehler beim Generieren der Tabelle</strong></p>
                                <p>Generieren trat ein Fehler auf. Bitte versuche es erneut.</p>
                                <button @click.prevent="changePage(1)" class="btn btn-danger">Erneut versuchen</button>
                                <small class="d-block mt-3">Fehlermeldung: {{ displayError }}</small>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>

            <!-- Daten anzeigen? -->
            <tbody v-else-if="data != null && data.length >= 1">
                <tr v-for="(entry, index) in data" :key="index">
                    <td v-for="field in fields" :key="field.id"
                        :class="{ 'text-end': field.align == 'end', 'text-center': field.align == 'center', 'align-middle': field.valign == 'middle', 'd-none d-lg-table-cell': field.hide_mobile }">
                        <slot :name="field.key" :entry="entry">
                            <span class="loaded-value">{{ entry[field.key] }}</span>
                        </slot>
                    </td>
                </tr>
            </tbody>

            <!-- Keine Ergebnisse? -->
            <tbody v-else-if="data != null && data.length == 0">
                <tr>
                    <td colspan="100%">
                        <slot name="no_result_set">
                            <p class="p-3 text-center m-0">
                                <i>Keine Ergebnisse.</i>
                            </p>
                        </slot>
                    </td>
                </tr>
            </tbody>

            <!-- Anzeigen von tatsächlichen Daten -->
            <tbody v-else-if="currentLoadingSimulatedRows != 0">
                <tr v-for="index in currentLoadingSimulatedRows">
                    <td v-for="field in fields" :key="field.id">
                        <slot :name="field.key + '_loading'">
                            <BlankTextComponent :length="8" />
                        </slot>
                    </td>
                </tr>
            </tbody>

            <!-- Generische Ladeanimation -->
            <tbody v-else>
                <tr>
                    <td colspan="100%" class="text-center p-4">
                        <font-awesome-icon icon="fa-solid fa-spinner" spin-pulse />
                        Lade...
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    
    <slot name="belowTable"></slot>

    <!-- Seiten in Desktop-Ansicht -->
    <div v-if="hasPages" class="ms-2 me-2 mb-2 d-none d-md-block">
        <p>
            <small>
                Zeige Datensatz {{ pagination.offset + 1 }} bis {{ pagination.offset + data.length }}
                von {{ pagination.max }} Datensätzen.
            </small>
        </p>

        <ul class="pagination pagination-sm">
            <li class="page-item">
                <span class="page-link" :class="{ 'disabled': isFirstPage }"
                    @click="changePage(isFirstPage ? 1 : currentPage - 1)">&lt;</span>
            </li>
            <template v-if="countPages <= 12">
                <!-- Unter 12 Seiten: alle anzeigen -->
                <li class="page-item" v-for="index in countPages">
                    <span class="page-link" :class="{ 'disabled': currentPage == index }" @click="changePage(index)">
                        {{ index }}
                    </span>
                </li>
            </template>
            <template v-else-if="currentPage <= 4">
                <!-- Über 10 Seiten, aber die aktuelle Seite ist kleiner als 4: die ersten 5 und die letzten 5 anzeigen -->
                <li class="page-item" v-for="index in 5">
                    <span class="page-link" :class="{ 'disabled': currentPage == index }" @click="changePage(index)">
                        {{ index }}
                    </span>
                </li>
                <li class="page-item"><span class="page-link text-black">...</span></li>
                <li class="page-item" v-for="index in 5">
                    <span class="page-link" @click="changePage(countPages + (index - 5))">
                        {{ countPages + (index - 5) }}
                    </span>
                </li>
            </template>
            <template v-else-if="currentPage >= countPages - 2">
                <!-- Über 10 Seiten, aber die aktuelle Seite ist in den letzten 5 Seiten; die ersten und die letzten 5 anzeigen -->
                <li class="page-item" v-for="index in 5">
                    <span class="page-link" :class="{ 'disabled': currentPage == index }" @click="changePage(index)">
                        {{ index }}
                    </span>
                </li>
                <li class="page-item"><span class="page-link text-black">...</span></li>
                <li class="page-item" v-for="index in 5">
                    <span class="page-link" :class="{ 'disabled': currentPage == countPages + (index - 5) }"
                        @click="changePage(countPages + (index - 5))">
                        {{ countPages + (index - 5) }}
                    </span>
                </li>
            </template>
            <template v-else>
                <!-- Wir sind mittendrin; die ersten drei, der direkt vor und nach der aktuellen Seite sowie die letzten 3 anzeigen -->
                <li class="page-item" v-for="index in 2">
                    <span class="page-link" @click="changePage(index)">
                        {{ index }}
                    </span>
                </li>
                <li class="page-item"><span class="page-link text-black">...</span></li>
                <li class="page-item" v-for="index in 3">
                    <span class="page-link" :class="{ 'disabled': index == 2 }"
                        @click="changePage(currentPage + 2 - (3 - index) - 1)">
                        {{ currentPage + 2 - (3 - index) - 1 }}
                    </span>
                </li>
                <li class="page-item"><span class="page-link text-black">...</span></li>
                <li class="page-item" v-for="index in 2">
                    <span class="page-link" @click="changePage(countPages + (index - 2))">
                        {{ countPages - (1 - index) }}
                    </span>
                </li>
            </template>
            <li class="page-item">
                <span class="page-link" :class="{ 'disabled': isLastPage }"
                    @click="changePage(isLastPage ? currentPage : currentPage + 1)">
                    &gt;
                </span>
            </li>
        </ul>
    </div>

    <div v-if="hasPages" class="ms-2 me-2 mb-2 d-block d-md-none">
        <p>
            <small>
                Zeige Datensatz {{ pagination.offset + 1 }} bis {{ pagination.offset + data.length }}
                von {{ pagination.max }} Datensätzen.
            </small>
        </p>

        <div class="input-group">
            <div class="input-group-prepend">
                <button class="btn btn-outline-secondary" type="button" :class="{ 'disabled': isFirstPage }"
                    @click="changePage(isFirstPage ? 1 : currentPage - 1)">
                    &lt;
                </button>
            </div>
            <select class="form-control text-center" @change="changePage($event.target.value)">
                <option v-for="pageCounter in countPages" :key="pageCounter" :selected="currentPage == pageCounter">
                    {{ pageCounter }}
                </option>
            </select>
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" :class="{ 'disabled': isLastPage }"
                    @click="changePage(isLastPage ? currentPage : currentPage + 1)">
                    &gt;
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>
table {
    max-width: 100%;
}

.page-link {
    cursor: pointer;
    min-width: 32px;
    text-align: center;
}
</style>