<script setup>
import { ref } from 'vue';
import axios from 'axios';

import { useCurrentUserStore } from '@/stores/currentUser';
const currentUserStore = useCurrentUserStore();

const isScreenEnabled = ref(false);

const lastRequests = ref({
    queue: []
});

axios.interceptors.request.use(request => {
    lastRequests.value.queue.push({
        type: "request",
        method: request.method,
        auth: request.headers && request.headers['X-Access-Token'] ? request.headers['X-Access-Token'].substr(0, 18) : 'no',
        url: request.url,
        data: null,
    });

    while (lastRequests.value.queue.length >= 20)
        lastRequests.value.queue.shift();

    return request;
})
axios.interceptors.response.use(response => {
    lastRequests.value.queue.push({
        type: "response",
        method: response.config.method,
        auth: response.config.headers && response.config.headers['X-Access-Token'] ? response.config.headers['X-Access-Token'].substr(0, 18) : 'no',
        url: response.config.url,
        data: JSON.stringify(response.data, null, 2)
    });

    while (lastRequests.value.queue.length >= 20)
        lastRequests.value.queue.shift();

    return response;
})

</script>
<template>
    <div class="overlay" v-show="currentUserStore.debugEnabled && isScreenEnabled">
        <div class="container pt-2">
            <table class="table table-striped">
                <thead>
                    <th>
                        Typ
                    </th>
                    <th>
                        Methode
                    </th>
                    <th>
                        Auth?
                    </th>
                    <th>
                        URL
                    </th>
                    <th>
                        Daten
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in lastRequests.queue" :key="index">
                        <td>{{ data.type }}</td>
                        <td>{{ data.method }}</td>
                        <td>{{ data.auth }}</td>
                        <td>{{ data.url }}</td>
                        <td>
                            <pre v-if="data.data != null">{{ data.data }}</pre>
                            <pre v-else>none</pre>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
    <button class="debug-mode btn btn-success" v-show="currentUserStore.debugEnabled" @click="isScreenEnabled = !isScreenEnabled">
        <span class="me-2">Debug-Modus aktiv!</span>
        <font-awesome-icon icon="fa-solid fa-bug" />
    </button>
</template>

<style scoped>
.debug-mode {
    position: fixed;
    bottom: 20px;
    right: 40px;
    z-index: 999999999999;
}

.overlay {
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 99999999999;
    padding-bottom: 50px;
    overflow-y: scroll;
}

.container {
    max-width: 100%;
}

table {
    max-width: 100%;
}

pre {
    max-height: 110px;
    background-color: #CCC;
    padding: 5px;
}
</style>