<script setup>
import { v4 as uuidv4 } from 'uuid';
import { ref, watch, onMounted } from 'vue';

import raw from '@/lib/api/raw.js';

import { useLayoutStore } from '@/stores/layout';
const layoutStore = useLayoutStore();

import BigbagLink from '@/components/common/assets/Bigbag.vue';
import CustomerLink from '@/components/common/assets/Customer.vue';
import InboundLink from '@/components/common/assets/Inbound.vue';
import MaterialLink from '@/components/common/assets/Material.vue';
import ParkingspaceLink from '@/components/common/assets/Parkingspace.vue';
import StoragelocationLink from '@/components/common/assets/Storagelocation.vue';
import ShiftbookPersonLink from '@/components/common/assets/ShiftbookPerson.vue';
import OrderLink from '@/components/common/assets/Order.vue';

const currentSearchText = ref('');

const searchState = ref({
    currentSearchId: null,
    isSearching: false,
});

const results = ref([]);

const doSearch = async () => {
    if (currentSearchText.value.trim().length == 0) return;
    const searchStartId = searchState.value.currentSearchId;
    searchState.value.isSearching = true;
    var data = null;
    try {
        data = await raw.get("/v1/search?q=" + encodeURIComponent(currentSearchText.value));
    } catch (e) { }
    if (searchStartId != searchState.value.currentSearchId) return;
    searchState.value.isSearching = false;
    if (!data || !data.data) return;
    results.value = data.data;
};

watch(currentSearchText, (to, from) => {
    if (to == from) return;
    results.value = [];
    const myTriggeredId = uuidv4();
    searchState.value.currentSearchId = myTriggeredId;
    searchState.value.isSearching = false;
});

const myinput = ref(null);
onMounted(() => {
    myinput.value.focus();
});
</script>

<template>
    <div class="overlay">
        <div class="container pt-3">
            <div class="input-group">
                <input class="form-control" ref="myinput" v-model="currentSearchText" v-debounce:500="doSearch" />
                <div class="input-group-append bg-white" style="overflow: hidden;">
                    <button class="btn text-danger" @click="layoutStore.isSearchVisible = false">
                        Schließen
                    </button>
                </div>
            </div>


            <div class="card mt-3 d-none d-md-block">
                <div class="card-body p-1 ps-2 pe-2">
                    <small>
                        Tipp: für präzisieres Suchen können folgende Filter hinzugefügt werden:
                        <code><u>/a</u>uftrag</code>,
                        <code><u>/b</u>igbag</code>,
                        <code><u>/k</u>unde</code>,
                        <code><u>/l</u>ager</code>,
                        <code><u>/ma</u>terial</code>,
                        <code><u>/me</u>ingang</code>,
                        <code><u>/mi</u>tarbeiter</code>,
                        <code><u>/v</u>erkaufslager</code>.<br />
                        Suchbegriffe können von der Suche ausgeschlossen werden, indem Sie mit einem Minus versehen werden;
                        z.B. <code>/ma handaufgabe -bunt -klaro</code>
                    </small>
                </div>
            </div>

            <div class="card mt-3">
                <div class="card-body p-0">
                    <table class="table table-stripped mb-0">
                        <thead>
                            <tr>
                                <th>Typ</th>
                                <th>Ergebnis</th>
                                <th class="text-end">Match</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="currentSearchText.trim().length == 0">
                                <td colspan="100%" class="p-3 text-center"><i>Bitte Suchtext eingeben</i></td>
                            </tr>
                            <tr v-else-if="searchState.isSearching">
                                <td colspan="100%" class="p-3 text-center"><i>Suche...</i></td>
                            </tr>
                            <tr v-else-if="results.length == 0">
                                <td colspan="100%" class="p-3 text-center"><i>Keine Ergebnisse</i></td>
                            </tr>
                            <tr v-else v-for="(result, index) in results" :key="index">
                                <td>
                                    <template v-if="result.type == 'bigbag'">Bigbag</template>
                                    <template v-else-if="result.type == 'customer'">Kunde</template>
                                    <template v-else-if="result.type == 'inbound'">Mat.eingang</template>
                                    <template v-else-if="result.type == 'order'">Auftrag</template>
                                    <template v-else-if="result.type == 'material'">Material</template>
                                    <template v-else-if="result.type == 'parkingspace'">Mat.lager</template>
                                    <template v-else-if="result.type == 'storagelocation'">VK-Lager</template>
                                    <template v-else-if="result.type == 'shiftbook_person'">Mitarbeiter</template>
                                </td>
                                <td>
                                    <BigbagLink v-if="result.type == 'bigbag'" :asset="result.asset" />
                                    <CustomerLink v-else-if="result.type == 'customer'" :asset="result.asset" />
                                    <InboundLink v-else-if="result.type == 'inbound'" :asset="result.asset" />
                                    <OrderLink v-else-if="result.type == 'order'" :asset="result.asset" />
                                    <MaterialLink v-else-if="result.type == 'material'" :asset="result.asset" />
                                    <ParkingspaceLink v-else-if="result.type == 'parkingspace'" :asset="result.asset" />
                                    <StoragelocationLink v-else-if="result.type == 'storagelocation'"
                                        :asset="result.asset" />
                                    <ShiftbookPersonLink v-else-if="result.type == 'shiftbook_person'"
                                        :asset="result.asset" />
                                    <pre style="max-height: 40px;" v-else>{{ result.asset }}</pre>
                                </td>
                                <td class="text-end">
                                    {{ Math.floor(result.score * 100) / 100 }} %
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 100dvw;
    height: 100dvh;
    overflow: hidden;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999999;
}
</style>