<script setup>
import { RouterLink } from 'vue-router';

import { useCurrentUserStore } from '@/stores/currentUser';
const currentUserStore = useCurrentUserStore();

const version = __APP_VERSION__;
const buildDate = __BUILD_DATE__;

const logout = () => {
    currentUserStore.logout();
};


import changePasswordDialog from '@/lib/dialog/changePassword';
const changePassword = () => {
    changePasswordDialog();
}

import { useLayoutStore } from '@/stores/layout';
const layoutStore = useLayoutStore();

import { useServersideOptionsStore } from '@/stores/serversideOptions';
const ssoStore = useServersideOptionsStore();

import { emit } from 'se-bus';
const toggleSSOLayoutMode = () => {
    ssoStore.setOption('layout_mode', (ssoStore.options.layout_mode == 'block' ? 'small' : 'block'));
    layoutStore.isBottomMenuSidebarActive = false;
    emit('ssostore.update', { layout_mode: ssoStore.options.layout_mode });
}
</script>

<template>
    <ul class="dropdown-menu dropdown-menu-dark text-small shadow show" data-popper-placement="top-start">
        <li class="text-center">
            <small class="text-light">pfr-db.de v{{ version }}</small>
            <small class="ps-1" style="font-size: 9px;">({{ buildDate }})</small>
        </li>
        <li>
            <hr class="dropdown-divider">
        </li>
        <li>
            <small>
                <span class="dropdown-item" @click="changePassword()">Kennwort ändern</span>
            </small>
        </li>
        <li @click.prevent="toggleSSOLayoutMode()">
            <small>
                <span v-if="ssoStore.options.layout_mode == 'block'" class="dropdown-item">
                    Schmale Reports
                </span>
                <span class="dropdown-item" v-else>
                    Breite Reports
                </span>
            </small>
        </li>
        <PermissionComponent type="reports" min="read">
            <li>
                <small>
                    <RouterLink to="/report/sync/flakeanalyser" class="dropdown-item"
                        @click="layoutStore.isBottomMenuSidebarActive = false">
                        Flakeanalyser Sync
                    </RouterLink>
                </small>
            </li>
        </PermissionComponent>
        <li>
            <hr class="dropdown-divider">
        </li>
        <li>
            <small>
                <span class="dropdown-item" @click="logout()">Abmelden</span>
            </small>
        </li>
    </ul>
</template>

<style scoped>
.dropdown-item {
    cursor: pointer;
}
</style>